import React, {useContext} from 'react'
import TemplateThreeExpIntro from '../templates/3-Experience-Intro/TemplateThreeExpIntro'
import { ExperienceContext } from '..'
import audio from '../assets/audio/Page 3 - Experience .mp3'
// example assets (to be made dynamic)

function PageThree() {
  const appContext = useContext(ExperienceContext)
  const experienceIntro = appContext.experienceContent.data.experience_introduction_text || []
  const leftImage = appContext.experienceContent.data.experience_intro_image.url || ''
  return (
    <TemplateThreeExpIntro centerText={true} goldLines={true} buttonLink={"/preparation"} title="Your Experience" leftImage={leftImage} text={experienceIntro} expIntro={true} voiceOver={audio}/>
  )
}

export default PageThree