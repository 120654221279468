import React from 'react'
import TemplateSeven from '../templates/7-Large-Diagram/TemplateSeven'
import timelineAudio from '../assets/audio/Page 8 - Timeline.mp3'

function PageEightTimeline() {
  return (
    <TemplateSeven voiceOver={timelineAudio}  />
  )
}

export default PageEightTimeline