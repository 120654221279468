import React, { useContext } from 'react'
import TemplateTwo from '../templates/2-Welcome-Intro/TemplateTwoIntro'
import { ExperienceContext } from '..'
import MethodTwo from '../templates/12-  MethodTwo/MethodTwo'
import corkVideo from '../assets/coreContent/Page 17/corkvideo.mp4'
import audio from '../assets/audio/Page 17 - Dosage.mp3'


function MethodPageSeven() {
  const appContext = useContext(ExperienceContext)
  const corkImage = appContext.coreContent.data.page_17_top_right_image.url || ''
  const bottomRightText = appContext.coreContent.data.page_17_text_bottom_right || []
  const topLeftText = appContext.coreContent.data.page_17_text_top_left || []
  console.log(corkVideo)

  return (
    <MethodTwo title="Method Champenoise" flipLayout={true}
    bottomLeftText={bottomRightText}
    topRightText={topLeftText}
    useBottomVideo={true}
    image1Url={corkImage}
    image2Url={corkVideo}
    buttonLink="/tasting-notes-3"
    pageSix={true}
    voiceOver={audio}
    />

   // <TemplateTwo title="Method Champenoise" text={dosageText} image={corkImage} halfRoundedImage={true} buttonLink="/tasting-notes-3"/>
  )
}

export default MethodPageSeven