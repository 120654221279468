import React, {useState} from 'react'
import styled from 'styled-components/macro'
import Layout from '../Layout/Layout'
import GoldButton from '../../components/GoldButton/GoldButton'
import {useNavigate} from 'react-router'
import { PrismicRichText } from '@prismicio/react'

// textbox outlines
import topLeft from '../../assets/textBoxOutline/templateThree/topleft.png'
import topRight from '../../assets/textBoxOutline/templateThree/topright.png'
import bottomLeft from '../../assets/textBoxOutline/templateThree/bottomleft.png'
import { useEffect } from 'react'

function TemplateThreeExpIntro({title, leftImage, text, buttonLink, centerText, goldLines, headingDecor, finalButton, method, expIntro, riddling, voiceOver}) {
    const navigate = useNavigate()
    const [loading, setLoading] = useState(true)
  return (
    <Layout title={title} loading={loading} button={
        finalButton ? 
        <GoldButton onClickAction={() => window.location.assign('http://champagne-ology.com/')} voiceOver={voiceOver}>www.champagne-ology.com</GoldButton>
        :
        <GoldButton onClickAction={() => navigate(buttonLink)} voiceOver={voiceOver}>Continue</GoldButton>
        }>
        <TemplateThreeGrid className={`${method && 'methodgrid'}`}>
            <div className={`leftImage ${method && 'method'}`}>
                <img onLoad={() => setLoading(false)} src={leftImage} alt="Bottles of Champagne"/>
            </div>
            <div className={`${centerText ? 'centerText' : 'text'} ${headingDecor && 'heading-decor'} ${method && 'methodText'} ${finalButton && 'lastpage'} ${expIntro && 'experience-intro'} ${riddling && 'riddling'}`}>
               {goldLines && 
               <>
               <div className="topright">
                    <img src={topRight} alt="" />
                </div>
                <div className="topleft">
                    <img src={topLeft} alt="" />
                </div>
                <div className="bottomleft">
                    <img src={bottomLeft} alt="" />
                </div>
               </> }
                <PrismicRichText field={text}  />
            </div>
            {voiceOver && <audio src={voiceOver} />}
        </TemplateThreeGrid>
    </Layout>
  )
}

export default TemplateThreeExpIntro

const TemplateThreeGrid = styled.div`
    padding-top: 2rem;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: 4rem 1fr 3rem 1.2fr 4rem;
    grid-template-rows: 1fr auto auto;
    padding-bottom: 2rem;

    &.methodgrid {
        grid-template-columns: 4rem 1fr 5px 1.8fr 4rem;
        @media screen and (max-width: 1300px) {
        grid-template-columns: 2rem 1fr 3rem 1.2fr 2rem;
    }
    }
    @media screen and (max-width: 1300px) {
        grid-template-columns: 2rem 1fr 3rem 1.2fr 2rem;
    }
    @media screen and (min-width: 1600px){
        grid-template-columns: 4rem 1fr 2rem 2fr 4rem;
    };


    .leftImage {
        box-sizing: border-box;
        grid-column: 2/3;
        grid-row: 1 / 4;
        text-align: center;
        max-height: 100%;
        padding-right: 2rem;
        padding-bottom: 13px;
        overflow: hidden;
        display: flex;
        justify-content: center;
        img {
            max-height: calc(100vh - 160px);
            width:auto;
            height: auto;
            
        }

    }
        .method {
            padding-top: 2rem;
            justify-content: start;
        }
        
        .text {
        box-sizing: border-box;
        grid-column: 4/5;
        grid-row: 1/4;
        text-align: right;
        width: 100%;
        strong {
            font-weight: normal;
        }    

        h2 {
            margin-top: 1rem;
            text-align: center;
            font-family: 'Savoye LET Plain', Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
            font-size: 2rem;
            font-weight: normal;
        } 
    };

   
    .methodText {
        h2 {
            margin-top: 2rem;
        }
    }

    .heading-decor {
        h2 {
            ::before {
                   content:  url("data:image/svg+xml;charset=UTF-8, <svg xmlns='http://www.w3.org/2000/svg' version='1.1' height='20' width='110'><circle cx='10' cy='10' r='7' stroke='%23c4a05c' stroke-width='1' fill='%23fff' /><circle cx='40' cy='10' r='7' stroke='%23c4a05c' stroke-width='1' fill='%23fff' /><circle cx='70' cy='10' r='7' stroke='%23c4a05c' stroke-width='1' fill='%23fff' /></svg>");
                }
                ::after {
                    content:  url("data:image/svg+xml;charset=UTF-8, <svg xmlns='http://www.w3.org/2000/svg' version='1.1' height='20' width='110'><circle cx='40' cy='10' r='7' stroke='%23c4a05c' stroke-width='1' fill='%23fff' /><circle cx='70' cy='10' r='7' stroke='%23c4a05c' stroke-width='1' fill='%23fff' /><circle cx='100' cy='10' r='7' stroke='%23c4a05c' stroke-width='1' fill='%23fff' /></svg>");
                }
        }
    }
    
    .centerText {
        box-sizing: border-box;
        grid-column: 4/5;
        grid-row: 1/4;
        width: 100%;

        strong {
            font-weight: normal;
        }
        p {
            text-align: center;
            margin-bottom: .5rem;
        }
        h2 {
            text-align: center;
            font-family: 'Savoye LET Plain', Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
            font-size: 2rem;
            font-weight: normal;
            margin-top: 2.5rem
        }

    }
    .centerText.experience-intro {
        h2 {
            margin-bottom: 2rem
        }
        p {
           margin-bottom: 2rem; 
        }
    }

   .centerText.lastpage {
    overflow-y: none;
        @media screen and (min-width: 1300px) {
            p {
                margin-bottom: 2rem;
            }
        }
    }

    .text, .centerText {
        padding: 1rem;
        overflow-x: auto;
}
.text.riddling {
    h2:nth-of-type(2) {
        margin-top: 3rem;
    }
}

    .topleft {
        margin-top: -1rem;
        margin-left: -1rem;
        box-sizing: border-box;
        height: auto;
        width: auto;
        position: absolute;
        @media screen and (max-width: 1300px) {
            height: 30vh;
            width: 145px;
            overflow: hidden;
        }
    }

    .topright {
        margin-top: -1rem;
        //margin-right: -1rem;
        box-sizing: border-box;
        position: absolute;
        right: 4rem;
        height: 60vh;
       overflow: hidden;
        @media screen and (max-width: 1300px) {
            right: 2rem;
            width: 150px;
            img {
               position: absolute;
               right: 0;
            }
        }
        
    }

    .bottomleft {
        height: 200px;
        width: 200px;
        margin-left: -1rem;
        margin-bottom: 2rem;
        box-sizing: border-box;
        position: absolute;
        bottom: 2rem;
        overflow: hidden;
        @media screen and (max-width: 1300px) {
            height: 150px;
            position: fixed;
        }
        
       
        img {
            position: absolute;
            bottom: 0;
            left: 0;
        }
    }
   
`