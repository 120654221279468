import React, {useState, useEffect} from 'react'
import { PrismicRichText } from '@prismicio/react'
import styled from 'styled-components'
import GoldButton from '../../components/GoldButton/GoldButton'
import Layout from '../Layout/Layout'
import {useNavigate} from 'react-router-dom'

function MethodTwo({title, voiceOver, wideTopText, buttonLink, bottomLeftText, topRightText, image2Url, image1Url, flipLayout, shrinkCentreColumn, useVideo, useBottomVideo, marginTop, pageSix, pageTwo}) {
  const navigate = useNavigate()
  const [loading, setLoading] = useState(true)
  const [image1Loaded, setImage1Loaded] = useState(() => useVideo ? true : false)
  const [image2Loaded, setImage2Loaded] = useState(() => useBottomVideo ? true : false)
  // CMS Assets
  useEffect(() => {

    if(image1Loaded && image2Loaded) {
      setLoading(false)
    }
    if(!image1Url && image2Loaded) {
      setLoading(false)
    }
    console.log(image1Loaded, image2Loaded)
   
  }, [image1Loaded, image2Loaded, image1Url])



  return (
    <Layout loading={loading} title={title} button={<GoldButton onClickAction={() => navigate(buttonLink)} voiceOver={voiceOver}>Continue</GoldButton>}>
      <Grid className={`${shrinkCentreColumn && "shrinkCentreCol"} ${wideTopText && 'fatBottomRow'} ${marginTop && 'margintop'} ${pageSix && 'pageSix'}`}>
        <div className={flipLayout ? "barrelsFlipped" : "barrels"}>
          {useVideo ?
           <video 
            autoPlay={true}
            controls={false}
            loop={true}
            width={500}
            
            >
              <source src={image1Url} type="video/mp4"></source>
           </video>
            :
            <img src={image1Url} alt="" onLoad={(() => setImage1Loaded(true))}/>
        }
        </div>
        <div className={`${flipLayout ? "rightTextFlipped" :"rightText"} ${wideTopText && 'wide'}`}>
          <PrismicRichText field={topRightText} />
        </div>
        <div className={`${flipLayout ? "fermentorsFlipped" : "fermentors"} ${pageTwo && 'fermentorsPageTwo'}`}>
          {useBottomVideo ? 
          <video 
          autoPlay={true}
          controls={false}
          loop={true}        
          >
            <source src={image2Url} type="video/mp4"></source>
            </video>
          :
        <img src={image2Url} alt="" onLoad={() => setImage2Loaded(true)} />
          }
        </div>
        <div className={`${flipLayout ? "leftTextFlipped" : "leftText"} ${pageTwo && 'pageTwo'}`}>
          <PrismicRichText field={bottomLeftText} />
        </div>
      </Grid>
      {voiceOver && <audio src={voiceOver} />}
    </Layout>
  )
}

export default MethodTwo

const Grid = styled.div`
display: grid;
box-sizing: border-box;
max-width: 100%;
height: 100%;
grid-template-rows: 1fr 1.2fr;
grid-template-columns: 1fr 1fr 1fr;
margin: 0 2rem;
padding-bottom: 10px;
row-gap: 1rem;
padding: 0 2rem;
align-content: center;

@media screen and (min-width: 1600px) {
  grid-template-columns: 1.25fr 1fr 1fr;
}

&.margintop {
  margin-top: 2rem;
}

&.shrinkCentreCol {
  grid-template-columns: 1fr 0.05fr 1fr;
}
&.fatBottomRow {
  grid-template-rows: 1fr 1.5fr;
}
&.pageSix {
  grid-template-columns: 1fr .3fr 1fr;
}
strong {
  font-weight: normal;
}

h2 {
  text-align: center;
  ::before {
    content:  url("data:image/svg+xml;charset=UTF-8, <svg xmlns='http://www.w3.org/2000/svg' version='1.1' height='20' width='110'><circle cx='10' cy='10' r='7' stroke='%23c4a05c' stroke-width='1' fill='%23fff' /><circle cx='40' cy='10' r='7' stroke='%23c4a05c' stroke-width='1' fill='%23fff' /><circle cx='70' cy='10' r='7' stroke='%23c4a05c' stroke-width='1' fill='%23fff' /></svg>");
  }
  ::after {
    content:  url("data:image/svg+xml;charset=UTF-8, <svg xmlns='http://www.w3.org/2000/svg' version='1.1' height='20' width='110'><circle cx='40' cy='10' r='7' stroke='%23c4a05c' stroke-width='1' fill='%23fff' /><circle cx='70' cy='10' r='7' stroke='%23c4a05c' stroke-width='1' fill='%23fff' /><circle cx='100' cy='10' r='7' stroke='%23c4a05c' stroke-width='1' fill='%23fff' /></svg>");
  }
}
@media screen and (max-width: 1300px) {
  grid-template-columns: 1.2fr .5fr 1fr;
  grid-template-rows: 1fr 1.2fr;
  margin: 1rem;
  padding: 0 1rem;
  margin-bottom: 0;
  
  h2 {
    margin: 0.5rem;
  text-align: center;
  ::before {
    content:  url("data:image/svg+xml;charset=UTF-8, <svg xmlns='http://www.w3.org/2000/svg' version='1.1' height='20' width='110'><circle cx='40' cy='10' r='7' stroke='%23c4a05c' stroke-width='1' fill='%23fff' /><circle cx='70' cy='10' r='7' stroke='%23c4a05c' stroke-width='1' fill='%23fff' /></svg>");
  }
  ::after {
    content:  url("data:image/svg+xml;charset=UTF-8, <svg xmlns='http://www.w3.org/2000/svg' version='1.1' height='20' width='110'><circle cx='40' cy='10' r='7' stroke='%23c4a05c' stroke-width='1' fill='%23fff' /><circle cx='70' cy='10' r='7' stroke='%23c4a05c' stroke-width='1' fill='%23fff' /></svg>");
  }
}
} 

div {
  box-sizing: border-box;
}
.barrels {
  align-self: center;
  grid-row: 1 /2;
  grid-column: 1 / 3;
  overflow: hidden;
  @media screen and (max-width: 1300px) {
  align-self: end;
}
  

  img {
    height: auto;
    width: 100%;
    @media screen and (max-width: 1300px) {
    align-self: end;
    height: 25vh;
    width: auto;
}
  }

  
}
.barrelsFlipped {
  height: 100%;
  align-self: center;
  grid-row: 1/ 2;
  grid-column: 3/ 4;
  overflow: hidden;
  display: flex;
  flex-flow: column nowrap;
  justify-content: end;
  align-items: flex-start;
  padding-left: 2rem;

  img {
    height: auto;
    width: 100%;
  }
  
  
}
.rightText {
  align-self: center;
  padding: 0 .5rem;
  grid-row: 1/ 2;
  grid-column: 3/ 4;
  @media screen and (max-width: 1300px) {
  align-self: end;
}
}
.wide {
  grid-column: 1 / 4;
  width: 100%;
}
.rightTextFlipped {
  align-self: center;
  padding: 0 .5rem;
  grid-row: 1 /2;
  grid-column: 1 / 3;
}
.leftText {
  padding: 0 1rem 0 0.5rem;
  grid-row: 2 / 3;
  grid-column: 1 / 2;
  align-self: start;
  h2 {
    margin-top: 2rem;
  }
  @media screen and (max-width: 1300px) {
    align-self: end;
    height: 100%;
    margin-bottom: 1rem;
    display: flex;
    flex-flow: column nowrap;
    justify-content: start;
    h2 {margin-top: 1rem;}
    padding: 0 .5rem;
  }
  p {
    margin: .25rem 0;
  }
}
.pageTwo {
  @media screen and (max-width:1300px) {
    justify-content: end;
  }
}
.leftTextFlipped {
  box-sizing: border-box;
  padding: 0 0.5rem;
  grid-row: 2 / 3;
  grid-column: 3 / 4;
  align-self: start;
  padding-left: 2rem;
  @media screen and (max-width: 1300px){
    padding-bottom: 4rem;    
   
  }
  h2 {
    margin-top: 2rem;
  }
}
.fermentors {
  height: 100%;
  grid-row: 2 / 3;
  grid-column: 2 / 4;
  overflow: hidden;
  align-self: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: end;
  @media screen and (max-width: 1300px){
    padding-bottom: 1rem;    
  }

  img {
    max-height: 100%;
    width: 100%;
    position: relative;
    right: -2px;
  }
}
.fermentorsPageTwo {
  img {
    @media screen and (max-width: 1300px){
    height: 82%;    
  }
  }
}
.fermentorsFlipped {
  height: 100%;
  grid-row: 2 / 3;
  grid-column: 1 / 3;
  overflow: hidden;
  position: relative;
  align-self: start;
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  justify-content: flex-end;
  @media screen and (max-width: 1300px){
    padding-bottom: 2rem;    
  }
  img {
    height: auto;
    width: 100%;
    margin-bottom: 2rem;
    @media screen and (max-width: 1300px){
    height: 80%;
    width: auto;   
  }
}
}
  video {
    width: 100%;
    margin-left: 0;
    height: auto;
  }
`