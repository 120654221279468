import React, { useState } from 'react'
import styled from 'styled-components/macro'
import {AiOutlineMinus} from 'react-icons/ai'
import {BsPlayFill, BsPause} from 'react-icons/bs'
import { useEffect } from 'react'
import PlayModal from '../PlayModal'


function GoldButton({children, onClickAction, disabled, voiceOver, bgMusic, extraAudio, firstPage = false}) {
    const [showPlayModal, setShowPlayModal] = useState(false)
    const audio = voiceOver ? new Audio(voiceOver) : null
    const triviaIntro = extraAudio ? new Audio(extraAudio) : null
    function playAudio() {
        if(bgMusic) {
            audio.volume = 0.08;
            triviaIntro.play();
        }
        audio.play().catch((err) => {
            if(firstPage && err.name === 'NotAllowedError') {
                setShowPlayModal(true);
            }
        
        })
    }
    function pauseAudio() {
        audio.pause()
        if(extraAudio) triviaIntro.pause();
    }

    useEffect(() => {
        if(voiceOver) playAudio()
        return () => {
            if(voiceOver) {
                pauseAudio()
            }
        }
    })

  return (
    <>
    { voiceOver ?
    <>
        {showPlayModal && <PlayModal close={() => setShowPlayModal(false)} />}
        <MediaButton>
        <BsPlayFill onClick={playAudio} />
        <NewButton type="button" disabled={disabled} onClick={onClickAction}>
            <span className="text">
                {children}
            </span>
        </NewButton>
            <BsPause onClick={pauseAudio} />
        </MediaButton>
    </>
        : <OldGoldButton onClickAction={onClickAction} disabled={disabled}>{children}</OldGoldButton> }
    </>
  )
}


export function OldGoldButton({children, onClickAction, disabled}) {
    return (
      <Button type="button" disabled={disabled} onClick={onClickAction}>
      <AiOutlineMinus />
          <span className="text">
              {children}
          </span>
          <AiOutlineMinus />
      </Button>
    )
  }
  
export default GoldButton


const MediaButton = styled.div`
font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    box-sizing: border-box;
    background-color: #fff;
    border: none;
    padding: 0rem .5rem;
    transition: background-color .2s ease-in-out;
    text-align: center;
    vertical-align: middle;
    height: 40px;
    display: flex;
    align-items: center;
    border: 1px solid #C49F5C;
    color: #C49F5C;
    svg {
        color: #C49F5C;
    }
    :disabled {
        background-color: #fff;
        .text, svg {
            color: #a19379;
        }
        :hover {
            background-color: #fff;
            cursor: unset;
            .text, svg {
            color: #a19379;
        }
    }
    }

    @media screen and (max-width: 1000px) {
        height: 30px;
        font-size: .7rem;       
    }

    .text {
        margin: 0 .5rem;
        margin-bottom: 4px;
        color: #C49F5C;

    }

    :hover {
       background-color: #c8ae7d;
       .text, svg {
        color: #fff;
       }
    }


`

const NewButton = styled.div`
font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    box-sizing: border-box;
    border: none;
    cursor: pointer;
    padding: 0rem .5rem;
 
    text-align: center;
    vertical-align: middle;
    height: 38px;
    display: flex;
    align-items: center;
    border: none;
    
    color: #C49F5C;
    svg {
        color: #C49F5C;
    }
    :disabled {
        background-color: #fff;
        .text, svg {
            color: #a19379;
        }
}

    @media screen and (max-width: 1000px) {
        height: 30px;
        font-size: .7rem;       
    }

    .text {
        margin: 0 .5rem;
        margin-bottom: 4px;
        color: #C49F5C;

    }


`


const Button = styled.button`
font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    box-sizing: border-box;
    background-color: #fff;
    border: none;
    cursor: pointer;
    padding: 0rem .5rem;
    transition: background-color .2s ease-in-out;
    text-align: center;
    vertical-align: middle;
    height: 40px;
    display: flex;
    align-items: center;
    border: 1px solid #C49F5C;
    color: #C49F5C;
    svg {
        color: #C49F5C;
    }
    :disabled {
        background-color: #fff;
        .text, svg {
            color: #a19379;
        }
        :hover {
            background-color: #fff;
            cursor: unset;
            .text, svg {
            color: #a19379;
        }
    }
}

    @media screen and (max-width: 1000px) {
        height: 30px;
        font-size: .7rem;       
    }

    .text {
        margin: 0 .5rem;
        margin-bottom: 4px;
        color: #C49F5C;

    }

    :hover {
       background-color: #c8ae7d;
       .text, svg {
        color: #fff;
       }
    }


`