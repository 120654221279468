import React, {useState} from 'react'
import styled from 'styled-components/macro'
import GoldButton from '../../components/GoldButton/GoldButton'
import Layout from '../Layout/Layout'
import { useNavigate } from 'react-router-dom'

export default function TemplateOneWelcome({title, heroImage, centreText}) {
    const navigate = useNavigate()
    const [loading, setLoading] = useState(true)
    
  
  return (
    <Layout title={title} loading={loading}>
        <Container>
        <div className="centreContent" >
            <img src={centreText} alt="Welcome to Champagnology" className="heading" />
            <GoldButton onClickAction={() => navigate('/welcome-intro')}>Start Your Experience</GoldButton>
        </div>
        <div className="filter">
            <div className="imageClip">
                <img  onLoad={() => setLoading(false)} className='hero' alt="Bottles of Champagne" src={heroImage} />
            </div>
        </div>
        </Container>
    </Layout>
  )
}

const Container = styled.div`
    box-sizing: border-box;
    padding-top: 50px;
    height:100%;

    .filter {
        height: 100%;
        filter:drop-shadow(7px 7px 5px rgba(0,0,0, 0.5));
        .imageClip {
            width: 100%;
            max-height: 100%;
            -webkit-clip-path: polygon(0 0, 100% 0, 100% 80%, 0% 100%);
             clip-path: polygon(0 0, 100% 0, 100% 80%, 0% 100%);
             
            .hero {
                width: 100%;
                box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.5);
            }
        }
    }

    .centreContent {
       
        z-index: 5;
        position: absolute;
        width: 100%;
        display: flex;
        flex-flow: column nowrap;
        justify-content: flex-start;
        align-items: center;
        padding-top: 100px;

        img.heading {
            height: 200px;
        }
       
       
    }

`