import {
    ayala,
    billecart,
    delamotte,
    ghMumm,
    lansonBlack,
    lansonRose,
    laurentPernier,
    moetImperial,
    moetRose,
    polRoger,
    tattingerRose
} from '../assets/audio/tastings/index';




export const tastingAudio = {
    "chardonnay":  {
        t1: moetImperial,
        t2: laurentPernier,
        t3: lansonBlack
    },
    "equal-blend": {
        t1: laurentPernier,
        t2: polRoger,
        t3: lansonBlack
    },
    "pinot-meunier": {
        t1: lansonBlack,
        t2: billecart,
        t3: laurentPernier
    },
    "pinot-meunier-and-pinot-noir": {
        t1: lansonBlack,
        t2: moetImperial,
        t3: laurentPernier
    },
    "rose-pinot": {
        t1: moetRose,
        t2: tattingerRose,
        t3: lansonRose
    },
    "chardonnay-pinot-noir": {
        t1: moetImperial,
        t2: ayala,
        t3: laurentPernier
    },
    "pinot-noir": {
        t1: moetImperial,
        t2: ghMumm,
        t3: laurentPernier
    },
    'blanc-de-blanc': {
        t1: laurentPernier,
        t2: delamotte,
        t3: lansonBlack
    }
}




