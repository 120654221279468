import answer1 from './a1.mp3'
import answer2 from './a2.mp3'
import answer3 from './a3.mp3'
import answer4 from './a4.mp3'
import answer5 from './a5.mp3'
import answer6 from './a6.mp3'
import answer7 from './a7.mp3'
import answer8 from './a8.mp3'
import question1 from './q1.mp3'
import question2 from './q2.mp3'
import question3 from './q3.mp3'
import question4 from './q4.mp3'
import question5 from './q5.mp3'
import question6 from './q6.mp3'
import question7 from './q7.mp3'
import question8 from './q8.mp3'

export const triviaAudio = {
    question1,
    question2,
    question3,
    question4,
    question5,
    question6,
    question7,
    question8,
    answer1,
    answer2,
    answer3,
    answer4,
    answer5,
    answer6,
    answer7,
    answer8
}