import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import Layout from '../Layout/Layout'
import GoldButton from '../../components/GoldButton/GoldButton'
import { useNavigate } from 'react-router-dom'
import { PrismicRichText } from '@prismicio/react'

// Static image
import pageLogo from '../../assets/svg/Large Page Logo-01.png'

function TemplateEight({title, image, video, text, buttonLink, vintages, voiceOver}) {
  const navigate = useNavigate()
  const [loading, setLoading] = useState(true)
  useEffect(() => {
    if(!image) {
      const to = setTimeout(() => {
        setLoading(false)
        return clearTimeout(to)
      }, 300)
    }
  })
  return (
    <Layout loading={loading} title={title} button={<GoldButton onClickAction={() => navigate(buttonLink)} voiceOver={voiceOver}>Continue</GoldButton>}>
    <TemplateFiveGrid>
      <div className="leftImage">
      {video && <video 
      autoPlay={true}
      controls={false}
      loop={true}
      height="600"
      >
        <source src={video} type="video/mp4"></source></video>}
      {image && <img onLoad={() => setLoading(false)} src={image} alt="" />}
      </div>
      <div className={`textContianer ${vintages && "vintages"}`}>
        <PrismicRichText field={text} />
      </div>
    </TemplateFiveGrid>
    {voiceOver && <audio src={voiceOver} />}
    </Layout>
  )
}

export default TemplateEight

const TemplateFiveGrid = styled.div`
    box-sizing: border-box;
    height: 100%;
    display: grid;
    grid-template-columns: 4rem 2fr 20px 4fr 4rem;
    grid-template-rows: 2rem 1fr 1.75rem 4rem;
    margin-top: 2rem;

    @media screen and (max-width: 1300px) {
      grid-template-columns: 2em 2fr 20px 4fr 2rem;
      grid-template-rows: 1rem 1fr 1.75rem 4rem;
    }

    
    .leftImage {
      box-sizing: border-box;
      grid-column: 2 /3;
      grid-row: 1 / 4;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: start;
      max-height: 100%;
     
      img {
        max-width: 100%;
        max-height: 100%;
        box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.5);
      }
      video {
        height: 100%;
        width: auto;
      }
    }

    .textContianer {
      max-height: 100%;
      box-sizing: border-box;
      grid-column: 4 / 5;
      grid-row: 1 / 4;
      overflow-y: auto;
      padding-left: 2rem;
      p, li, strong {
        font-weight: normal;
        margin-bottom: 0;
      }
      li {
        margin: .5rem 0;
        strong {
          line-height: 1rem;
          font-size: 1.4rem;
        }

        @media screen and (max-height: 760px) {
          margin: .25rem 0;
        }
        @media screen and (min-height: 1200px) {
          margin: 1rem 0;
        
      }

    }
      h2 {
            margin: 0 auto;
            text-align: center;
            font-family: 'Savoye LET Plain', Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
            font-size: 2rem;
            font-weight: normal;

       
            :nth-of-type(1) {
                margin-top: 1rem;
                ::before {
                   content:  url("data:image/svg+xml;charset=UTF-8, <svg xmlns='http://www.w3.org/2000/svg' version='1.1' height='20' width='110'><circle cx='10' cy='10' r='7' stroke='%23c4a05c' stroke-width='1' fill='%23fff' /><circle cx='40' cy='10' r='7' stroke='%23c4a05c' stroke-width='1' fill='%23fff' /><circle cx='70' cy='10' r='7' stroke='%23c4a05c' stroke-width='1' fill='%23fff' /></svg>");
                }
                ::after {
                    content:  url("data:image/svg+xml;charset=UTF-8, <svg xmlns='http://www.w3.org/2000/svg' version='1.1' height='20' width='110'><circle cx='40' cy='10' r='7' stroke='%23c4a05c' stroke-width='1' fill='%23fff' /><circle cx='70' cy='10' r='7' stroke='%23c4a05c' stroke-width='1' fill='%23fff' /><circle cx='100' cy='10' r='7' stroke='%23c4a05c' stroke-width='1' fill='%23fff' /></svg>");
                }
            }
            :nth-of-type(2) {

                ::before {
                   content:  url("data:image/svg+xml;charset=UTF-8, <svg xmlns='http://www.w3.org/2000/svg' version='1.1' height='20' width='110'><circle cx='40' cy='10' r='7' stroke='%23c4a05c' stroke-width='1' fill='%23fff' /><circle cx='70' cy='10' r='7' stroke='%23c4a05c' stroke-width='1' fill='%23fff' /></svg>");
                }
                ::after {
                    content:  url("data:image/svg+xml;charset=UTF-8, <svg xmlns='http://www.w3.org/2000/svg' version='1.1' height='20' width='110'><circle cx='40' cy='10' r='7' stroke='%23c4a05c' stroke-width='1' fill='%23fff' /><circle cx='70' cy='10' r='7' stroke='%23c4a05c' stroke-width='1' fill='%23fff' /></svg>");
                }
            }
            :nth-of-type(3) {
                ::before {
                   content:  url("data:image/svg+xml;charset=UTF-8, <svg xmlns='http://www.w3.org/2000/svg' version='1.1' height='20' width='110'><circle cx='70' cy='10' r='7' stroke='%23c4a05c' stroke-width='1' fill='%23fff' /></svg>");
                }
                ::after {
                    content:  url("data:image/svg+xml;charset=UTF-8, <svg xmlns='http://www.w3.org/2000/svg' version='1.1' height='20' width='110'><circle cx='40' cy='10' r='7' stroke='%23c4a05c' stroke-width='1' fill='%23fff' /></svg>");
                }
            }
        }  

        &.vintages {
          @media screen and (max-width: 1300px) {
            li, p {
              margin: 0.25rem;
              line-height: 1.1rem;
            }
          }
      }
    }

    .pageLogo {

      box-sizing: border-box;
      grid-column: 6 / 8;
      grid-row: 2 / 3;
      text-align: right;
      img {
        max-height: 365px;
        max-width: 100%;
        margin-right: 0;
      }
    }

   



`