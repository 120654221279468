import React, {useContext} from 'react'
import styled from 'styled-components/macro'
import './normalize.css'
import PageTitle from '../../components/PageTitle/PageTitle'
import NavBar from '../../components/NavBar/NavBar'
import { ExperienceContext } from '../..'

function Layout({children, button, loading}) {
    const context = useContext(ExperienceContext)
    const { title, lastname } = context.userDetails
  
  return (
    <Main className={loading && 'loading'}>
        <div className="title">
            <PageTitle className="title" title={title} name={lastname}  />
        </div>
        <div className="navbar">
            <NavBar />
        </div>
        <div className="content">
            {children}
        </div>
        {button && <div className="buttonContainer">{button}</div>}
    </Main>
  )
}

export default Layout

const Main = styled.main`
    box-sizing: border-box;
    max-height: 100vh;
    padding: 0;
    display: grid;
    grid-template-columns: 4rem repeat(8, 1fr) 4rem;
    grid-template-rows: 65px 50px calc(100vh - 135px) 20px; 
    transition: filter 1s ease-in-out;
    filter: opacity(100%);
    overflow: hidden;
  
    @media screen and (max-width: 1300px) {
        grid-template-columns: 2rem repeat(8, 1fr) 2rem;
    }
    @media screen and (device-width: 768px) and (device-height: 1024px) and (orientation:landscape) {
        height: -webkit-fill-available;
        position: fixed;
    }
    &.loading {
        filter: opacity(0);
    }
    
    a {
        text-decoration: none;
        color: inherit;
    }

    .title {
        grid-column: 2/10;
        grid-row: 1/2;
        font-family: 'Savoye LET Plain', Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
    }
    .navbar {
        grid-row: 2/3;
        grid-column: 2/5;
    }
    .content {
        box-sizing: border-box;
        grid-column: 1/11;
        grid-row: 3/4;        
        overscroll-behavior: none;
    }
    .buttonContainer {
        box-sizing: border-box;
        position: fixed;
        bottom: 20px;
        right: 4rem;
        @media screen and (max-width: 1300px) {
            right: 2rem;
        }
    }


`