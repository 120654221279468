import React, {useEffect, useState, useContext} from 'react'
import { ExperienceContext } from '../..'
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'
import Layout from '../Layout/Layout'
import TriviaBubble from '../../components/TriviaBubble/TriviaBubble'
import GoldButton from '../../components/GoldButton/GoldButton'

import subtitleText from '../../assets/coreContent/Page 19/Page 19 Text-01.svg'
// Question assets
import q1 from '../../assets/coreContent/Page 19/Questions/1.svg'
import q2 from '../../assets/coreContent/Page 19/Questions/2.svg'
import q3 from '../../assets/coreContent/Page 19/Questions/3.svg'
import q4 from '../../assets/coreContent/Page 19/Questions/4.svg'
import q5 from '../../assets/coreContent/Page 19/Questions/5.svg'
import q6 from '../../assets/coreContent/Page 19/Questions/6.svg'
import q7 from '../../assets/coreContent/Page 19/Questions/7.svg'
import q8 from '../../assets/coreContent/Page 19/Questions/8.svg'
// Anwer assets
import a1 from '../../assets/coreContent/Page 19/Answers/1.svg'
import a2 from '../../assets/coreContent/Page 19/Answers/2.svg'
import a3 from '../../assets/coreContent/Page 19/Answers/3.svg'
import a4 from '../../assets/coreContent/Page 19/Answers/4.svg'
import a5 from '../../assets/coreContent/Page 19/Answers/5.svg'
import a6 from '../../assets/coreContent/Page 19/Answers/6.svg'
import a7 from '../../assets/coreContent/Page 19/Answers/7.svg'
import a8 from '../../assets/coreContent/Page 19/Answers/8.svg'

import {triviaAudio} from '../../assets/audio/trivia/index'
import triviaIntroAudio from '../../assets/audio/trivia/intro.mp3'




function TemplateTenTrivia({music}) {
  const navigate = useNavigate()
  const bubbles = [
    {q: q1, a: a1, qvo: triviaAudio.question1, avo: triviaAudio.answer1},
    {q: q2, a: a2, qvo: triviaAudio.question2, avo: triviaAudio.answer2},
    {q: q3, a: a3, qvo: triviaAudio.question3, avo: triviaAudio.answer3},
    {q: q4, a: a4, qvo: triviaAudio.question4, avo: triviaAudio.answer4},
    {q: q5, a: a5, qvo: triviaAudio.question5, avo: triviaAudio.answer5},
    {q: q6, a: a6, qvo: triviaAudio.question6, avo: triviaAudio.answer6},
    {q: q7, a: a7, qvo: triviaAudio.question7, avo: triviaAudio.answer7},
    {q: q8, a: a8, qvo: triviaAudio.question8, avo: triviaAudio.answer8},
  ]

  // These assets are static, but simulate loading so transition is consistent with other apges
  const [loading, setLoading] = useState(true)
  const appContext = useContext(ExperienceContext)
  const triviaImage = appContext.experienceContent.data.trivia_image.url || ''
  useEffect(() => {
    const simulateLoad = setTimeout(() => setLoading(false), 300)
    return () => clearTimeout(simulateLoad)
  })
  return (
    <Layout loading={loading} title="Trivia" button={<GoldButton onClickAction={() => navigate('/au-revoir')} voiceOver={music} extraAudio={triviaIntroAudio} bgMusic={true}>Continue</GoldButton>}>
        <OuterGrid>
          <div className="subtitle">
            <p>Champagne trivia which you can muse over as you continue with your tasting, or even share at your next champagne mixer!</p>
            <strong>Click on a bubble to pop it!</strong>

          </div>
          <div className="innerGrid">
            {bubbles.map((bubble, index) => <TriviaBubble key={index} question={bubble.q} answer={bubble.a} questionAudio={bubble.qvo} answerAudio={bubble.avo} />)}
          </div>
          <div className="imageContainer">
            <img src={triviaImage} alt="" />
          </div>
        </OuterGrid>
    </Layout>
  )
}

export default TemplateTenTrivia

const OuterGrid = styled.div`
box-sizing: border-box;
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 6rem 1fr 6rem;
  grid-template-rows: auto 1fr;
  padding-bottom: 4rem;
 
  .subtitle {
    grid-row: 1/2;
    grid-column: 2 / 3;
    width: 100%;
    text-align: center;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    height: auto;
    margin-bottom: 2rem;
    p {
      /* font-size: 1.2rem; */
      text-align: center;
    }
    strong {
      font-size: 1.5rem;
      font-weight: normal;
    }
  }

  .innerGrid {
    grid-row: 2 / 3;
    grid-column: 2 / 3;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: auto auto;
    row-gap: 35px;
    column-gap: 45px;
    @media screen and (max-width: 1000px) {
    row-gap: 20px;
    column-gap: 20px;
    div {
      height: 170px;
      width: 170px;
    }
  }
    
    @media screen and (min-width: 1450px) {
      row-gap: 35px;
      column-gap: 45px;
      div {
        height: 220px;
        width: 220px;
      }

    }
  }
  .imageContainer {
    box-sizing: border-box;
    display: grid;
    position: fixed;
    width: 100%;
    bottom: 1rem;
    height: 25vh;
    z-index: -5;
    overflow: hidden;
    grid-template-columns: 4rem 1fr 4rem;
    @media screen and (max-width: 1300px) {
      grid-template-columns: 2rem 1fr 2rem;
    }

    img {
      grid-column: 2/ 3;
        width: 100%;
        height: auto;
        position: absolute;
        bottom: 3px;
        left: 0;
        @media screen and (max-width: 1300px) {
          bottom: 4px;
    }
    }
}
  
`

