import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import Layout from '../Layout/Layout'
import {PrismicRichText} from '@prismicio/react'
import GoldButton from '../../components/GoldButton/GoldButton'
import {useNavigate} from 'react-router-dom'
// Assets

import pageLogo from '../../assets/coreContent/Page 2/Large Page Logo-01.png'


function TemplateTwoIntro({title, text, image, bottomGraphic, roundedImage, halfRoundedImage, largerContent, buttonLink}) {
    const navigate = useNavigate()
    const [loading, setLoading] = useState(true)
    const [tallImageLoaded, setTallImageLoaded] = useState(false)
    const [logoLoaded, setLogoLoaded] = useState(false)
    const [dotsLoaded, setDotsLoaded] = useState(false)

    useEffect(() => {
        if(!bottomGraphic) setDotsLoaded(true)
        if(tallImageLoaded && logoLoaded && dotsLoaded) {
            setLoading(false)
        }
    }, [logoLoaded, tallImageLoaded, dotsLoaded, bottomGraphic])

  return (
    <Layout title={title} loading={loading} button={<GoldButton onClickAction={() => navigate(buttonLink)}>Continue</GoldButton>}>
        <TemplateTwoGrid className={largerContent && 'largerContent'}>
            <div className={`main-text  ${largerContent && 'expanded'}`}>
               <PrismicRichText field={text} />
            </div>
            <div className="tall-image">
                <img onLoad={() => setTallImageLoaded(true)} src={image} className={`${roundedImage && 'rounded'} ${halfRoundedImage && 'halfRounded'}`} alt="" />
            </div>
            <div className="company-logo">
                <img onLoad={() => setLogoLoaded(true)} src={pageLogo} alt="Page logo" />
            </div>
            <div className="dots">
                {bottomGraphic && <img onLoad={() => setDotsLoaded(true)} src={bottomGraphic} alt="" />}
            </div>
        </TemplateTwoGrid>
    </Layout>
  )
}

export default TemplateTwoIntro

const TemplateTwoGrid = styled.div`
    height: 100%;
    box-sizing: border-box;
    display: grid;
    grid-template-columns: 4rem repeat(5, 1fr) 30px repeat(3, 1fr) 50px 1fr 1fr  4rem;
    grid-template-rows: 60px calc(100% - 130px) 70px;
    padding-top: 50px;
    overflow: hidden;

    &.largerContent {
        grid-template-columns: 4rem repeat(5, 1.5fr) 30px repeat(3, 1fr) 10px .5fr 1fr  4rem;
        grid-template-rows: 60px calc(100% - 130px) 20px;
    }
   

    @media screen and (max-width: 1200px) {
        grid-template-columns: 4rem repeat(5, 1fr) 30px repeat(3, .75fr) 50px 1fr .5fr  2rem;
        @media screen and (max-height: 740px) {
            .main-text {
                overflow-y: scroll;
                max-width: 100%;
                p, ul {word-wrap: break-word; max-width: 100%;}
            }
        }
    }
    
    
    .main-text {
        box-sizing: border-box;
        grid-row: 1 / 3;
        height: 100%;
        grid-column: 2 / 7;
        overflow-y: auto;
        overflow-x: visible;
    
        ul {padding-left: 0;}
        li {margin: 0.5rem;}
        strong {margin: 0;}

        &.expanded {
            grid-row: 1 / 5;
            width: 100%;
            margin-top: -3rem;
            p:nth-child(1) {
                
                margin-bottom: 0;
            }
       
        }
    }

    .tall-image {
        box-sizing: border-box;
        grid-column: 8 / 11;
        grid-row: 1 / 4;
        max-height: 100%;
        display: flex;
        flex-flow: column nowrap;
        justify-content: flex-end;
        @media screen and (max-width: 1200px) {
           padding-bottom:4rem;
        }
        
        img {
            max-height: 100%;
            max-width: 100%;
            box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.5);
            &.rounded {
                border-radius: 50px;
            }
            &.halfRounded {border-radius: 50px 50px 0 0;}
        }
    }

    .company-logo {
        grid-row: 2 / 3;
        grid-column: 12 / -1;
        max-height: 350px;
        text-align: right;
        img {
            max-height: 100%;
            margin-right: 0;
        }
    }

    .dots {
        grid-column: 2 / 7;
        grid-row: 3/4;
        display: flex;
        justify-content: center;
        align-items: baseline;
        padding: 2rem;
    

        img {
            max-height: 50px;
        }

    }
`