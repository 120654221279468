import React, { useEffect, useContext, useState } from 'react'
import { useParams } from 'react-router-dom'
import { usePrismicDocumentByUID, useSinglePrismicDocument } from '@prismicio/react'
import { ExperienceContext } from '../index'
import TemplateOneWelcome from '../templates/1-Welcome/TemplateOneWelcome'
import headingText from '../assets/coreContent/Page 1/Page 1 Heading Text-01.png'
import { activateAccessCode } from '../utils/api'

function PageOne() {
  const appContext = useContext(ExperienceContext)
  const {experienceType} = useParams()
  
  const [coreContent, coreContentStatus] = useSinglePrismicDocument('shared_pages')
  const [experienceContent, experienceContentStatus] = usePrismicDocumentByUID('experience', appContext.experienceType )

  const [heroImage, setHeroImage] = useState(null)

  useEffect(() => {
    appContext.updateCoreContent(coreContent)
    appContext.updateExperienceContent(experienceContent)
    appContext.updateExperienceType(experienceType)
    console.log(appContext)
    setHeroImage(appContext.experienceContent?.data?.welcome_page_image?.url)
  }, [coreContent, experienceContent, appContext, coreContentStatus, experienceContentStatus, experienceType])
  
  useEffect(() => {
    async function setAccessCodeToactivated() {
      await activateAccessCode(appContext.accessCode)
    }
    setAccessCodeToactivated()
  }, [appContext.accessCode])

  const image = appContext.experienceContent?.data?.welcome_page_image?.url || ''

  if(coreContentStatus.state === 'loading' 
  || experienceContentStatus.state === 'loading'
  || appContext.experienceContent === {}
  || appContext.coreContent === {}
  || heroImage === null) {
    return <h1>Loading {experienceType}</h1>
  }

  console.log('Displaying content')



  return (
    <TemplateOneWelcome title={"Welcome"} heroImage={heroImage} centreText={headingText} />
  )
}

export default PageOne