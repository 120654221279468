import React, { useContext } from 'react'
import { ExperienceContext } from '..'
import TemplateEight from '../templates/8-Image-Info-Graphic/TemplateEight'
import glassesVideo from '../assets/coreContent/Page 9/glassCropped.mp4'
import vintagesAudio from '../assets/audio/Page 9 - Vintage.mp3'
function PageNineVintages() {
  const appContext = useContext(ExperienceContext)
  const vintagesText = appContext.coreContent.data.page_9_text__vintages_ || []
  return (
    <TemplateEight title="A Brief History" image={null} video={glassesVideo} text={vintagesText} buttonLink="/tasting-notes-2" vintages={true} voiceOver={vintagesAudio}/>
  )
}

export default PageNineVintages