import React, {useContext} from 'react'
import TemplateTwoIntro from '../templates/2-Welcome-Intro/TemplateTwoIntro'
import TextLeftImageRight from '../templates/TextLeftImageRight/TextLeftImageRight'
import { ExperienceContext } from '../index'
import tastingAudio from '../assets/audio/Page 5 - Tasting.mp3'
// Assets

function PageFive() {
  const appContext = useContext(ExperienceContext)
  const tastingText = appContext.coreContent.data.page_5_text || []
  const tastingImage = appContext.experienceContent.data.tasting_image.url || '' 
  return (
    <TextLeftImageRight text={tastingText} image={tastingImage}  buttonLink="/tasting-notes-1" tastingPage={true} voiceOver={tastingAudio}/>
    //<TemplateTwoIntro title="Tasting" image={tastingImage} text={tastingText} largerContent={true} buttonLink="/tasting-notes-1" />
  )
}

export default PageFive