import React from 'react'
import TemplateFourPrep from '../templates/4-Prep/TemplateFourPrep'
import audio from '../assets/audio/Page 4 - Preparation.mp3'

function PageFour() {
  return (
    <TemplateFourPrep voiceOver={audio}/>
  )
}

export default PageFour