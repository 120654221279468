import React from 'react'
import styled from 'styled-components/macro'
import sideLine from '../../assets/svg/SVGLine.svg'

function SquareButton({children, onClickAction}) {
  return (
    <Button>
        <span className='line'>
            <img src={sideLine} />
        </span>
        <span className="text">
            {children}
        </span>
        <span className='line'>
            <img src={sideLine} />
        </span>
        
   
    </Button>
  )
}

export default SquareButton

const Button = styled.button`
    box-sizing: border-box;
    background-color: #c49f5c;
    border: none;
    cursor: pointer;
    padding: 0rem .5rem;
    transition: background-color .2s ease-in-out;
    text-align: center;
    vertical-align: middle;
    height: 40px;

    .line {
        width: 16px;
        height: auto;
        vertical-align: middle;
        img {
            position: relative;
            bottom: 3px;
        }
    }
    .text {
        margin: 0 1rem;
        color: white;
        padding-top: 10px;
    }

    :hover {
       background-color: #c8ae7d;
    }


`