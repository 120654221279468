import React, { useState } from 'react'
import Layout from '../Layout/Layout'
import styled from 'styled-components'
import {useNavigate} from 'react-router-dom'
import {PrismicRichText} from '@prismicio/react'
import GoldButton from '../../components/GoldButton/GoldButton'

// textbox outlines
import topLeft from '../../assets/textBoxOutline/templateThree/topleft.png'
import topRight from '../../assets/textBoxOutline/templateThree/topright.png'
import bottomLeft from '../../assets/textBoxOutline/templateThree/bottomleft.png'

function TextLeftImageRight({title, image, text, buttonLink, tastingPage, voiceOver}) {
    const navigate = useNavigate()
    const [loading, setLoading] = useState(true)
  return (
    <Layout title={title}  loading={loading} button={<GoldButton onClickAction={() => navigate(buttonLink)} voiceOver={voiceOver}>Continue</GoldButton>}>
       <Grid>
            <div className="rightImage">
                <img onLoad={() => setLoading(false)} src={image} alt="" />
            </div>
            <div className={`text ${tastingPage && 'tastingPageText'}`}>
                    <div className="topright">
                        <img src={topRight} alt="" />
                    </div>
                    <div className="topleft">
                        <img src={topLeft} alt="" />
                    </div>
                    <div className="bottomleft">
                        <img src={bottomLeft} alt="" />
                    </div>
                    <div className='scroller'>
                    <PrismicRichText field={text} />
                    </div>
            </div>
       </Grid>
    </Layout>
  )
}

export default TextLeftImageRight

const Grid = styled.div`
    padding-top: 1rem;
    box-sizing: border-box;
    width: 100%;
    height: calc(100%);
    display: grid;
    grid-template-columns: 4rem 1.2fr 1rem 1fr 4rem;
    grid-template-rows: 1fr auto auto;
    padding-bottom: 1rem;
    @media screen and (max-width: 1300px) {
        grid-template-columns: 2rem 1.2fr 2rem 1fr 2rem;
        padding-bottom: 0;
    }



    .rightImage {
        box-sizing: border-box;
        grid-column: 4/5;
        grid-row: 2 / 4;
        text-align: right;
        max-height: 100%;
        padding-top: 0.5rem;
        display: flex;
        flex-flow: column nowrap;
        justify-content: center;
        align-items: flex-end;
        img {
            max-height: calc(100vh - 160px);
            max-width: 100%;
        }
        @media screen and (max-width: 1300px) {
            max-width: 100%;
            overflow: hidden;
            img {
                height: 100%;
                width: auto;
                max-width: unset;
            }
        }
    }

    .text {
        box-sizing: border-box;
        position: relative;
        grid-column: 2/3;
        grid-row: 2 / 4;
        text-align: right;
        width: 100%;
        padding: 0.5rem;
        overflow: hidden;
        height: 102%;
        .scroller {
            box-sizing: border-box;
            padding: 0 1rem;
            z-index: 5;
            
            height: 100%;
        }
       p:first-of-type {
            text-align: center;
            margin-top: 3rem;
            margin-bottom: 1rem;
            @media screen and (max-width: 1300px) {
                margin-top: 1.5rem;
            }
        }
        strong {
            font-family: 'Savoye LET Plain', Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
            font-size: 2rem;
            font-weight: normal;
            margin: 0;
        }

        h2 {
            margin-top: 1rem;
            text-align: center;
            font-family: 'Savoye LET Plain', Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
            font-size: 2rem;
            font-weight: normal;
            
        }
        ul {
            margin: 0;
            font-size: 1rem;
        }
        li {
            margin-bottom: 0.25rem;
        }
        p {
            margin: .25rem 0;
        }
        p:last-of-type {
            text-align: center;
            margin-top: 1rem;   
        }

    };
    .text.tastingPageText {
        p:first-of-type{
            margin-top: 1.75rem;
        }
        ul {
            margin-bottom: .75rem;
            padding-left: 20px;
        }
        @media screen and (min-width: 1300px) {
            ul {
                margin-bottom: 1.25rem;
                padding-left: 20px;
            }
        }
    }
    .text, .centerText {
        overflow-x: auto;
        @media screen and (min-width: 1600px){
            h2 {
                font-size: 3rem;
            }
            p {
                font-size: 1.5rem;
            }
    }
}

    .topleft {
        margin-top: 0;
        margin-left: -8px;
        box-sizing: border-box;
        height: auto;
        width: auto;
        position: absolute;
        z-index: -1;
     
    }

    .topright {
        margin-top: 0rem;
        margin-right: -1rem;
        box-sizing: border-box;
        position: absolute;
        right: 1rem;
        height: 60vh;
        overflow: hidden;
        z-index: -1;
    }

    .bottomleft {
        height: 200px;
        width: 200px;
        margin-left: -8px;
        margin-bottom: 0.5rem;
        box-sizing: border-box;
        position: absolute;
        bottom: -.5rem;
        overflow: hidden;
        z-index: -1;
        
       
        img {
            position: absolute;
            bottom: 0;
            left: 0;
        }
    }
`