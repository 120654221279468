import React, { useContext } from 'react'
import TemplateTwo from '../templates/2-Welcome-Intro/TemplateTwoIntro'
import { ExperienceContext } from '..'
import MethodTwo from '../templates/12-  MethodTwo/MethodTwo'
import audio from '../assets/audio/Page 13 - First Fermentation.mp3'

function MethodPageTwo() {
  const appContext = useContext(ExperienceContext)
  const bottomLeftText = appContext.coreContent.data.page_13_text_bottom_left || []
  const topRightText = appContext.coreContent.data.page_13_text__method_2_ || []
  const image1Url = appContext.coreContent.data.page_13_image__method_2_.url || ''
  const image2Url = appContext.coreContent.data.page_13_image_2.url || ''

  return (
    <MethodTwo image1Url={image1Url}
    image2Url={image2Url}
    bottomLeftText={bottomLeftText}
    topRightText={topRightText}
    appContext={appContext}
    title="Method Champenoise"
    buttonLink="/method-3"
    pageTwo={true}
    voiceOver={audio}
    />

    //<TemplateTwo title="Method Champenoise" text={content} image={barrels} halfRoundedImage={true} buttonLink="/method-3"  />
  )
}

export default MethodPageTwo