import React, {useState} from 'react'
import styled from 'styled-components'
import Layout from '../Layout/Layout'
import SquareButton from '../../components/SquareButton/SquareButton'

function TemplateEleven({image}) {
  const [loading, setLoading] = useState(true)
  return (
    <Layout loading={loading} title={<br />} button={<SquareButton>www.champagne-ology.com</SquareButton>}>
        <Container>
          <div className="filter">
            <div className="imageClip">
              <img onLoad={() => setLoading(false)} src={image} alt="" />
            </div>
          </div>
        </Container>
    </Layout>
  )
}

export default TemplateEleven

const Container = styled.div`
box-sizing: border-box;
padding-top: 50px;
    height: 100%;
    width: 100%;
    overflow: hidden;
      .filter {
        height: 100%;
        filter:drop-shadow(7px 7px 5px rgba(0,0,0, 0.5));
        .imageClip {
          height: 100%;
          width: 100%;
          clip-path: polygon(0 0, 100% 0, 100% 80%, 0% 100%);
          -webkit-clip-path: polygon(0 0, 100% 0, 100% 80%, 0% 100%);
          img {
            width: 100%;
          }
        }
      }

`