import React from 'react'
import styled from 'styled-components'
import play from '../assets/play.png'
import { OldGoldButton } from './GoldButton/GoldButton'

function PlayModal({close}) {
  return (
    <Backdrop>
        <Message>
            <p>Use the controls at the bottom right of the screen to control the voice over for your experience. The voice over can be paused at any time by pressing the pause button. You can continue playing the voice over by pressing the play button.</p>
            <img src={play} alt="play button" />
            <OldGoldButton onClickAction={close}>Close</OldGoldButton>
        </Message>
    </Backdrop>
  )
}

export default PlayModal

const Backdrop = styled.div`
position: fixed;
top: 0;
left: 0;
box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100vw;
    background-color: rgba(0,0,0,0.5);
`
const Message = styled.div`
height: auto;
width: 300px;
    padding: 2rem;
    border: 1px solid #C49F5C;
    border-radius: 10px;
    text-align: center;
    background-color: #fff;
    box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.5);
    display: flex;
    flex-flow: column nowrap;
    align-items: center;

    p {
        text-align: center;
    }

    img {
        width: 10rem;
        height: auto;
        margin-bottom: 1rem;
    }

`