import React, {useContext, useEffect, useState} from 'react'
import wideImage from '../assets/coreContent/Page 2/wideimage.png'
import { useParams } from 'react-router-dom'
import { usePrismicDocumentByUID, useSinglePrismicDocument } from '@prismicio/react'
import { ExperienceContext } from '../index'
import { activateAccessCode } from '../utils/api'
import IntroTwo from '../templates/2a-IntroTwo/IntroTwo'
import audio from '../assets/audio/Page 2 - Welcome.mp3'

function PageTwo() {
  const appContext = useContext(ExperienceContext)
  const {experienceType} = useParams()
  const page2Text = appContext.coreContent?.data?.page_2_text || []
  const [coreContent, coreContentStatus] = useSinglePrismicDocument('shared_pages')
  const [experienceContent, experienceContentStatus] = usePrismicDocumentByUID('experience', appContext.experienceType )
  const [introText, setIntroText] = useState([])
  const [loading, setLoading] = useState(true)
  // load app
  useEffect(() => {
    appContext.updateCoreContent(coreContent)
    appContext.updateExperienceContent(experienceContent)
    appContext.updateExperienceType(experienceType)
    setIntroText(appContext.coreContent?.data?.page_2_text)
    console.log(appContext)
  }, [coreContent, experienceContent, appContext, coreContentStatus, experienceContentStatus, experienceType])

  useEffect(() => {
    async function setAccessCodeToactivated() {
      await activateAccessCode(appContext.accessCode)
    }
    setAccessCodeToactivated()
  }, [appContext.accessCode])

  useEffect(() => {

  if(coreContentStatus.state === 'loading' 
  || experienceContentStatus.state === 'loading'
  || appContext.experienceContent === {}
  || appContext.coreContent === {}
  ){
    setLoading(true)
  } else {
    setLoading(false)
  }

  })

  return (
    // <TemplateTwoIntro title={"Welcome"} image={page2Image} text={page2Text} bottomGraphic={dots} roundedImage={true} buttonLink="/experience-intro" />
    <IntroTwo firstPage={true} loading={loading} text={introText} image={wideImage} voiceOver={audio}  buttonLink="/experience-intro"/>
    )
}

export default PageTwo