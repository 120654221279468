import React, {useState} from 'react'
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'
import Layout from '../Layout/Layout'
import GoldButton from '../../components/GoldButton/GoldButton'
import {PrismicRichText} from '@prismicio/react'


// textbox outlines
import topLeft from '../../assets/textBoxOutline/templateThree/topleft.png'
import topRight from '../../assets/textBoxOutline/templateThree/topright.png'
import bottomLeft from '../../assets/textBoxOutline/templateThree/bottomleft.png'

function TemplateFive({title, image, content, buttonLink, textFields, voiceOver}) {
    const navigate = useNavigate()
    const [loading, setLoading] = useState(true)
    const {name, style, blend, fermentation, timeOnLees, description, dosage, producer, effervescence, appearance, bouqet, palate, foodPairings} = textFields
  return (
    <Layout loading={loading} title={title} button={<GoldButton onClickAction={() => navigate(buttonLink)} voiceOver={voiceOver}>Continue</GoldButton>}>
        <TastingGrid>
            <div className="leftImage">
                <img onLoad={() => setLoading(false)} src={image} alt="A bottle of Champagne" />
            </div>
            <div className="text">
                <div className="topright">
                    <img src={topRight} alt="" />
                </div>
                <div className="topleft">
                    <img src={topLeft} alt="" />
                </div>
                <div className="bottomleft">
                    <img src={bottomLeft} alt="" />
                </div>
                <div className="textfields">
                <h2>{name}</h2>
                <p className='description'>{description}</p>
                <TastingNotesGrid>
                    <strong>Style</strong>
                    <p>{style}</p>
                    <strong>Grape Blend</strong>
                    <p>{blend}</p>
                    <strong>Fermentation</strong>
                    <p>{fermentation}</p>
                    <strong>Time On Lees</strong>
                    <p>{timeOnLees}</p>
                    <strong>Dosage (g/l)</strong>
                    <p>{dosage}</p>
                    <strong>Producer Type</strong>
                    <p>{producer}</p>
                </TastingNotesGrid>
                <h2>Tasting Notes</h2>
                <TastingNotesGrid>
                    <strong>Effervescence</strong>
                    <p>{effervescence}</p>
                    <strong>Appearance</strong>
                    <p>{appearance}</p>
                    <strong>Bouqet</strong>
                    <p>{bouqet}</p>
                    <strong>Palate</strong>
                    <p>{palate}</p>
                    <strong>Food Pairings</strong>
                    <p>{foodPairings}</p>
                </TastingNotesGrid>
               </div>
            </div>
        </TastingGrid>
        {voiceOver && <audio src={voiceOver} />}
    </Layout>
  )
}

export default TemplateFive


const TastingNotesGrid = styled.div`
    display: grid;
    grid-template-columns: auto 1fr;
    grid-auto-rows: auto;
    grid-auto-flow: row;
    align-items: baseline;
    
    strong {
        grid-column: 1/2;
        text-align: left;

    }
    p {
        grid-column: 2/3;
        text-align: left;
        margin-top: 0;
        padding-left: 1rem;
        padding-right: 1rem;
        margin-bottom: 0.5rem;
    }
`

const TastingGrid = styled.div`
    padding-top: 2rem;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: 4rem 1fr 1rem 1.5fr 4rem;
    grid-template-rows: 1fr auto auto;

    @media screen and (max-width: 1300px) {
        grid-template-columns: 2rem 1fr 2rem 1.3fr 2rem;
    };


    padding-bottom: 2rem;
    .leftImage {
        box-sizing: border-box;
        grid-column: 2/3;
        grid-row: 1 / 4;
        text-align: left;
        max-height: 100%;
        max-width: 100%;
        padding-right: 2rem;
        text-align: center;
        img {
            height: 100%;
            max-width: 100%;
            flex-shrink: 0;
        }

        @media screen and (max-width: 1300px) {
            overflow: hidden;
            img {
                height: 100%;
                max-width: unset;
            }
        }
    }

    .text {
        box-sizing: border-box;
        grid-column: 4/5;
        grid-row: 1/4;
        text-align: right;
        padding-right: .5rem;
        width: 100%;
        p.description {
            text-align: center;
            margin-bottom: 1rem;
        }
        strong {
            font-family: 'Savoye LET Plain', Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
            font-size: 1.3rem;
            font-weight: normal;
            @media screen and (min-width: 1600px){
                font-size: 1.7rem;
            }
        }
   
        h2 {
            margin: 0 auto;
            text-align: center;
            font-family: 'Savoye LET Plain', Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
            font-size: 2rem;
            font-weight: normal;
            margin-top: 2rem;
            @media screen and (min-width: 1600px){
                margin-top: 4rem;
            }
            @media screen and (max-width: 1300px) {
                margin-top: 1rem;
            }
       
            :nth-of-type(1) {
                margin-top: 1.5rem;
                margin-bottom: 1rem;
                @media screen and (min-width: 1600px){
                margin-top: 4rem;
            }
            @media screen and (max-width: 1300px) {
                margin-top: 1rem;
            }
       
                ::before {
                   content:  url("data:image/svg+xml;charset=UTF-8, <svg xmlns='http://www.w3.org/2000/svg' version='1.1' height='20' width='110'><circle cx='10' cy='10' r='7' stroke='%23c4a05c' stroke-width='1' fill='%23fff' /><circle cx='40' cy='10' r='7' stroke='%23c4a05c' stroke-width='1' fill='%23fff' /><circle cx='70' cy='10' r='7' stroke='%23c4a05c' stroke-width='1' fill='%23fff' /></svg>");
                }
                ::after {
                    content:  url("data:image/svg+xml;charset=UTF-8, <svg xmlns='http://www.w3.org/2000/svg' version='1.1' height='20' width='110'><circle cx='40' cy='10' r='7' stroke='%23c4a05c' stroke-width='1' fill='%23fff' /><circle cx='70' cy='10' r='7' stroke='%23c4a05c' stroke-width='1' fill='%23fff' /><circle cx='100' cy='10' r='7' stroke='%23c4a05c' stroke-width='1' fill='%23fff' /></svg>");
                }
            }
            :nth-of-type(2) {
                margin-bottom:1rem;
                margin-top: 1rem;

                ::before {
                   content:  url("data:image/svg+xml;charset=UTF-8, <svg xmlns='http://www.w3.org/2000/svg' version='1.1' height='20' width='110'><circle cx='40' cy='10' r='7' stroke='%23c4a05c' stroke-width='1' fill='%23fff' /><circle cx='70' cy='10' r='7' stroke='%23c4a05c' stroke-width='1' fill='%23fff' /></svg>");
                }
                ::after {
                    content:  url("data:image/svg+xml;charset=UTF-8, <svg xmlns='http://www.w3.org/2000/svg' version='1.1' height='20' width='110'><circle cx='40' cy='10' r='7' stroke='%23c4a05c' stroke-width='1' fill='%23fff' /><circle cx='70' cy='10' r='7' stroke='%23c4a05c' stroke-width='1' fill='%23fff' /></svg>");
                }
            }
            :nth-of-type(3) {
                ::before {
                   content:  url("data:image/svg+xml;charset=UTF-8, <svg xmlns='http://www.w3.org/2000/svg' version='1.1' height='20' width='110'><circle cx='70' cy='10' r='7' stroke='%23c4a05c' stroke-width='1' fill='%23fff' /></svg>");
                }
                ::after {
                    content:  url("data:image/svg+xml;charset=UTF-8, <svg xmlns='http://www.w3.org/2000/svg' version='1.1' height='20' width='110'><circle cx='40' cy='10' r='7' stroke='%23c4a05c' stroke-width='1' fill='%23fff' /></svg>");
                }
            }
        }       
    };


    .text, .centerText {
        padding: 1rem;
  


    .topleft {
        margin-top: -1rem;
        margin-left: -1rem;
        box-sizing: border-box;
        height: auto;
        width: auto;
        position: absolute;
        @media screen and (max-width: 1300px) {
            width: 150px;
            height: 200px;
            overflow: hidden;
        }
     
    }

    .topright {
        margin-top: -1rem;
        margin-right: 5px;
        box-sizing: border-box;
        position: absolute;
        right: 3.5rem;
        height: 60vh;
        overflow: hidden;
        @media screen and (max-width: 1300px) {
            right: 1.5rem;
            width: 150px;
            height: 60vh;
            img {
                
                position: absolute;
                right: 0;
            }
        }
    }

    .bottomleft {
        height: 200px;
        width: 200px;
        margin-left: -1rem;
        margin-bottom: 0;
        box-sizing: border-box;
        position: fixed;
        bottom: 2rem;
        overflow: hidden;
       
        img {
            position: absolute;
            top: 0;
            left: 0;
            height: 90%
        }
    }
}
   
`
