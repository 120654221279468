import React, { useContext, useState } from 'react'
import styled from 'styled-components'
import Layout from '../Layout/Layout'
import GoldButton from '../../components/GoldButton/GoldButton'
import {useNavigate} from 'react-router-dom'
import { PrismicRichText } from '@prismicio/react'
import glasses from '../../assets/coreContent/Page 4/new/glasses.png'
import ice from '../../assets/coreContent/Page 4/new/ice.png'
import cork from '../../assets/coreContent/Page 4/new/cork.png'
import cheese from '../../assets/coreContent/Page 4/new/cheese.png'
import { ExperienceContext } from '../../index'


function TemplateFourPrep({voiceOver}) {
    const navigate = useNavigate()
    const appContext = useContext(ExperienceContext)
    const {text1, text2, text3, text4} = appContext.coreContent.data
    console.log(appContext.coreContent.data)
    console.log(voiceOver)


  return (
    <Layout title={"Preparation"} loading={false} button={<GoldButton onClickAction={() => navigate('/tasting-card')} voiceOver={voiceOver}>Continue</GoldButton>}>
        <TemplateFourGrid>
           <GraphicContainer>
                <div className="imgCont img1">
                    <img src={ice} alt="ice bucket" />
                </div>
                <div className="text 2">
                    <PrismicRichText field={text2} />
                </div>
                <div className="imgCont img3">
                    <img src={glasses} alt="ice bucket" />
                </div>
                <div className="text 4">
                    <PrismicRichText field={text4} />
                </div>
                <div className="text 5">
                    <PrismicRichText field={text1} />
                </div>
                <div className="imgCont 6">
                    <img src={cork} alt="cork" />
                </div>
                <div className="text 7">
                    <PrismicRichText field={text3} />
                </div>
                <div className='imgCont 8'>
                    <img src={cheese} alt="cheese board" />
                </div>
           </GraphicContainer>
        </TemplateFourGrid>   
        {voiceOver && <audio src={voiceOver} />}     
    </Layout>
  )
}

export default TemplateFourPrep

const TemplateFourGrid = styled.div`
    height: 100%;
    width: 100vw;
    box-sizing: border-box;
    padding: 0 4rem;
    padding-bottom: 40px;
    @media screen and (max-width: 1300px) {
        padding: 0 2rem;
        padding-top: 40px;
    } 
    `
const GraphicContainer = styled.div`
        grid-row: 1 / 2;
        grid-column: 1/2;
        margin: 0 auto;
        box-sizing: border-box;
        height: 100%;
        width: 100%;
        display: grid;
        grid-template-columns: repeat(4, 25%);
        grid-template-rows: repeat(2, 50%); 
        @media screen and (max-width: 1300px) {
            grid-template-rows: repeat(2, calc((100vw - 4rem) / 4 )) 
    } 
                
        div {
            box-sizing: border-box;
            height: 100%;
            width: 100%;
            img {
                object-fit: cover;
                max-height: 100%;
                max-width: 100%;
                width: 100%;
                height: auto;
            }
            &.text {
                padding: 0 .5rem;
                text-align: justify;
                }
            }
            p {
                margin-top: 0;
            }
            h2 {
                text-align: center;
                margin-top: .5rem;
            }
        
        .imgCont {
            display: flex;
            :last-of-type {
                justify-content: end;
            }
        }
        .img1, .img3 {
            align-self: end;
        }
        
        @media screen and (max-width: 1200px) {
            row-gap: 0;
            max-width: 100%;
            /* div {
                width: 220px;
                height: 200px;
            } */
        }
        @media screen and (min-width: 2000px) {
            /* max-width: 1600px;
            max-height: 1000px; */
            /* div {
                height: 320px;
                width: 320px;
            }
            */
        }
        
        /* .1 {grid-row: 1 / 2; grid-column: 1 / 2;}
        .2 {grid-row: 1 / 2; grid-column: 2 / 3;}
        .3 {grid-row: 1 / 2; grid-column: 3 / 4;}
        .4 {grid-row: 1 / 2; grid-column: 4 / 5;}
        .5 {grid-row: 2 / 3; grid-column: 1 / 2;}
        .6 {grid-row: 2 / 3; grid-column: 2 / 3;}
        .7 {grid-row: 2 / 3; grid-column: 3 / 4;}
        .8 {grid-row: 2 / 3; grid-column: 4 / 5;} */
       
`