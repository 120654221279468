import React, {useEffect, useContext} from 'react'
import TemplateTen from '../templates/10-Trivia/TemplateTenTrivia'
import { ExperienceContext } from '..'
import music from '../assets/audio/triviamusic.mp3'

export default function Trivia() {
  const appContext = useContext(ExperienceContext)
  useEffect(() => {
    appContext.updateExperienceProgress(5)
  })
  return (
    <TemplateTen music={music} />
  )
}
