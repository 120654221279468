import React, { useContext } from 'react'
import TemplateEight from '../templates/8-Image-Info-Graphic/TemplateEight'
import { ExperienceContext } from '..'
import MethodTwo from '../templates/12-  MethodTwo/MethodTwo'
import animation from '../assets/coreContent/Page 14/new/blueglassvid.mp4'
import audio from '../assets/audio/Page 14 - Blending.mp3'

function MethodPageFour() {
  const appContext = useContext(ExperienceContext)
  console.log(appContext)
  const glassesImage = appContext.coreContent.data.page_14_image__method_3_.url || ''
  const topLeftText = appContext.coreContent.data.page_14_text_top_left || []
  const bottomRightText = appContext.coreContent.data.page_14_text_bottom_right || []
  return (
    <MethodTwo title="Method Champenoise" buttonLink="/method-4" 
    image1Url={animation}
    image2Url={glassesImage}
    topRightText={topLeftText}
    bottomLeftText={bottomRightText}
    flipLayout={true}
    shrinkCentreColumn={true}
    useVideo={true}
    marginTop={true}
    voiceOver={audio}/>
    //<TemplateEight title="Method Champenoise" image={glassesImage} text={text} buttonLink="/method-4"/>
  )
}

export default MethodPageFour