import React, { useContext } from 'react'
import TemplateEleven from '../templates/11-Image/TemplateEleven'
import { ExperienceContext } from '..'

function Final() {
  const appContext = useContext(ExperienceContext)
  const goodbyeImage = appContext.experienceContent.data.last_page_image.url || ''
  return (
    <TemplateEleven image={goodbyeImage} />
  )
}

export default Final