import React, {useState} from 'react'
import styled from 'styled-components'
import bubbleGraphic from '../../assets/svg/bubbleGraphic.svg'
import pop from '../../assets/coreContent/Page 19/popGraphic.svg'

function TriviaBubble({question, answer, questionAudio, answerAudio}) {
    const [bubbleState, setBubbleState] = useState('pop')
    const [fadeOut, setFadeOut] = useState(false)

    const qAud = new Audio(questionAudio)
    const aAud = new Audio(answerAudio)

    const playQuestion = () => qAud.play();
    const playAnswer = () => aAud.play();

    const revealBubble = () => {
        if(bubbleState !== 'answer') setFadeOut(true)
       const to =  setTimeout(() => {
            setFadeOut(false)
            if(bubbleState === 'pop'){
                setBubbleState('question');
                playQuestion();
            }
            if(bubbleState === 'question') {
                setBubbleState('answer');
                playAnswer();
            }
        }, 300)
    }

    const renderBubble = () => {
        if(bubbleState === 'pop') return pop
        if(bubbleState === 'question') return question
        if(bubbleState === 'answer') return answer
    }
  return (
    <Bubble onClick={revealBubble} className={fadeOut && 'fadeOut'}>
            <img src={renderBubble()} alt=""/>
    </Bubble>
  )
}

export default TriviaBubble


// Styles
const Bubble = styled.div`
box-sizing: border-box;
height: 170px;
width: 170px;
display: flex;
transition: all .3s ease-in-out;
cursor: pointer;
&.fadeOut {
    opacity: 0;
}
img {
    height: 100%;
    width: 100%;
}

`
