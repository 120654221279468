import React, { useContext } from 'react'
import styled from 'styled-components'
import Layout from '../Layout/Layout'
import GoldButton from '../../components/GoldButton/GoldButton'
import { useNavigate } from 'react-router-dom'
import { PrismicRichText } from '@prismicio/react'
import { ExperienceContext } from '../../index'
import historyBanner from '../../assets/coreContent/Page 7/history-banner.png'
// textbox outlines
import topLeft from '../../assets/textBoxOutline/templateThree/topleft.png'
import topRight from '../../assets/textBoxOutline/templateThree/topright.png'
import bottomLeft from '../../assets/textBoxOutline/templateThree/bottomleft.png'

function TemplateSix({image, voiceOver}) {
    const navigate = useNavigate()
    const appContext = useContext(ExperienceContext)
    const historyText = appContext.coreContent.data.page_7_text__history_1_ || []
  return (
    <Layout title="A Brief History" button={<GoldButton onClickAction={() => navigate('/timeline')} voiceOver={voiceOver}>Continue</GoldButton>}>
        <TemplateSixGrid>
            <div className="mainText">
                <div className="topright">
                    <img src={topRight} alt="" />
                </div>
                <div className="topleft">
                    <img src={topLeft} alt="" />
                </div>
                <div className="bottomleft">
                    <img src={bottomLeft} alt="" />
                </div>
                <div className="scroller">
                    <PrismicRichText field={historyText} />
                </div>
            </div>
            <div className="imageContainer">
                <img src={image} alt="" />
            </div>
        </TemplateSixGrid>
        {voiceOver && <audio src={voiceOver} />}
    </Layout>
  )
}

export default TemplateSix

const TemplateSixGrid = styled.div`
box-sizing: border-box;
display: grid;
margin-top: 2rem;
height: 100%;
grid-template-columns: 4rem 1fr 4rem;
grid-template-rows: 2rem 1fr 4rem calc(2rem - 20px) 2rem;


@media screen and (max-width: 1300px) {
    grid-template-columns: 2rem 1fr 2rem;
}


p, li, strong {
    list-style-type: none;
}
ul {
    margin-top: 0;
    padding-inline-start: .5rem;
}




.mainText {
    box-sizing: border-box;   
   background-color: rgba(255, 255, 255, 0.9);
    box-sizing: border-box;
    grid-row: 1 / 5;
    grid-column: 2 / 3;

    @media screen and (max-width: 1300px) {
        ul:nth-of-type(3) {
            margin-top: .5rem;
        }
        li, p {
            line-height: 1.3rem;
        }
    }
    p:first-of-type {
        text-align: center;
        margin: 1rem auto;
    }
 
    @media screen and (max-width: 1400px) {
        p {
        margin: 0 0.25rem;
        :first-of-type {
            margin: 0.5rem auto;
        }
    }
    }

    @media screen and (max-width: 1750px) {
        p {
        margin: 0 0.5rem;
    }
    }
    @media screen and (min-width: 1850px) {
        p {
        margin: 1rem 0.5rem;
    }
    }
    @media screen and (min-width: 2000px) {
        p {
        margin: 1.5rem 0.5rem;
    }
    }
    strong {
        color: #C49F5C;
        font-weight: normal;
    }
    .scroller {
        box-sizing: border-box;
        padding: 1rem;
        padding-bottom: 0;
        position: relative;
        max-height: 100%;

    }
    .topleft {
        margin-top: 0;
        margin-left: 0;
        box-sizing: border-box;
        height: auto;
        width: auto;
        position: absolute;
     
    }

    .topright {
        margin-top: 0;
        margin-right: 0;
        box-sizing: border-box;
        position: absolute;
        right: 4rem;
        height: 60vh;
        overflow: hidden;
        @media screen and (max-width: 1300px) {
            right: 2rem;
        }
    }

    .bottomleft {
        height: 200px;
        width: 200px;
        margin-left: 0;

        box-sizing: border-box;
        position: fixed;
        bottom: 5.75rem;

       
    }
}

.imageContainer {
    position: fixed;
    width: 100%;
    bottom: -1rem;
    height: 25vh;
    z-index: -5;
    overflow: hidden;
    img {
        width: auto;
        height: 100%;
    }
}

`