import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import {PrismicProvider} from '@prismicio/react'
import {client} from './prismic'

import { BrowserRouter} from 'react-router-dom';

// Experience context - to determine the experience shown
const initialContext = {
  experienceType: '',
  coreContent: {},
  experienceContent: {},
  assetsLoading: false,
  accessCode: '',
  experienceProgress: 1,
  userDetails: {},
  updateCoreContent(content) {
    this.coreContent = content
  },
  updateExperienceContent(expContent) {
    this.experienceContent = expContent
  },
  updateExperienceType(expType) {
    this.experienceType = expType
  }
  ,
  setAssetsLoading(status) {
    this.assetsLoading = status;
  },
  setAccessCode(accessCode) {
    this.accessCode = accessCode
  },
  updateExperienceProgress(sectionNumber) {
    if (sectionNumber > this.experienceProgress) {
      this.experienceProgress = sectionNumber

    }
  } ,
  updateUserDetails(details) {
    this.userDetails = details
  }

}
export const ExperienceContext = React.createContext(initialContext)
document.addEventListener('touchmove', e => e.preventDefault())


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
    <ExperienceContext.Provider value={initialContext}>
      <PrismicProvider client={client}>
        <App />
      </PrismicProvider>
    </ExperienceContext.Provider>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
