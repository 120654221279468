import React, { useContext } from "react";
import TemplateTwo from "../templates/2-Welcome-Intro/TemplateTwoIntro";
import { ExperienceContext } from "..";
import TemplateThreeExpIntro from "../templates/3-Experience-Intro/TemplateThreeExpIntro";
import audio from '../assets/audio/Champagne-ology Page 16 - Riddling - Mastered.mp3'

function MethodPageSix() {
  const appContext = useContext(ExperienceContext);
  const text = appContext.coreContent.data.page_16_text__method_5_ || [];
  const image = appContext.coreContent.data.page_16_image__method_5_.url || "";
  return (
    <TemplateThreeExpIntro
      title="Method Champenoise"
      buttonLink="/method-6"
      goldLines={false}
      leftImage={image}
      text={text}
      headingDecor={true}
      method={true}
      riddling={true}
      voiceOver={audio}
    />
    // <TemplateTwo title={"Method Champenoise"} text={text} image={image} halfRoundedImage={true} buttonLink="/method-6" />
  );
}

export default MethodPageSix;
