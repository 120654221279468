import React, {useContext} from 'react'
import './App.css';
import {Routes, Route} from 'react-router-dom'

// Pages
import Login from './pages/Login/Login';
import PageOne from './pages/1-Welcome';
import PageTwo from './pages/2-Intro';
import PageThree from './pages/3-Experience-Intro'
import PageFour from './pages/4-Preparation';
import PageFive from './pages/5-TastingCard'
import PageSixTasting from './pages/6-TastingNotes';
import PageSevenHistory from './pages/7-History';
import PageEightTimeline from './pages/8-Timeline'
import PageNineVintages from './pages/9-Vintages'
import PageTenTastingTwo from './pages/10-TastingNotesTwo'
import PageElevenHarvest from './pages/11-Harvest'
import PageTwelveMethodOne from './pages/12-MethodPageOne'
import PageThirteenMethodTwo from './pages/13-MethodPageTwo'
import PageFourteenMethodThree from './pages/14-MethodPageThree.jsx'
import PageFifteenMethodFour from './pages/15-MethodPageFour'
import PageSixteenMethodFive from './pages/16-MethodPageFive.jsx'
import PageSeventeenMethodSix from './pages/17-MethodPageSix'
import PageEighteenTastingNotesThree from './pages/18-TastingNotesThree'
import PageNineteenTrivia from './pages/19-Trivia'
import PageTwenty from './pages/20-AuRevoir'
import LastPage from './pages/21-Final'
import { ExperienceContext } from '.';

function App() {
  const appContext = useContext(ExperienceContext)
  return (
    <div className={`App ${appContext.assetsLoading && 'loading'}`}>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="welcome/:experienceType" element={<PageOne />} />
          <Route path="welcome-intro/:experienceType" element={<PageTwo />} />
          <Route path="experience-intro" element={<PageThree />} />
          <Route path="preparation" element={<PageFour />} />
          <Route path="tasting-card" element={<PageFive />} />
          <Route path="tasting-notes-1" element={<PageSixTasting />} />
          <Route path="history" element={<PageSevenHistory />} />
          <Route path="timeline" element={<PageEightTimeline />} />
          <Route path="vintages" element={<PageNineVintages />} />
          <Route path="tasting-notes-2" element={<PageTenTastingTwo />} />
          <Route path="harvest" element={<PageElevenHarvest />} />
          <Route path="method-1" element={<PageTwelveMethodOne />} />
          <Route path="method-2" element={<PageThirteenMethodTwo />} />
          <Route path="method-3" element={<PageFourteenMethodThree />} />
          <Route path="method-4" element={<PageFifteenMethodFour />} />
          <Route path="method-5" element={<PageSixteenMethodFive />} />
          <Route path="method-6" element={<PageSeventeenMethodSix />} />
          <Route path="tasting-notes-3" element={<PageEighteenTastingNotesThree />} />
          <Route path="trivia" element={<PageNineteenTrivia />} />
          <Route path="au-revoir" element={<PageTwenty />} />
          <Route path="goodbye" element={<LastPage />} />
        </Routes>
    
     
      
    </div>
  );
}

export default App;
