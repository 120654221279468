import React, { useContext, useState, useEffect } from 'react'
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'
import { PrismicRichText } from '@prismicio/react'
import Layout from '../Layout/Layout'
import { ExperienceContext } from '../..'
import GoldButton from '../../components/GoldButton/GoldButton'

//Text and assets
import banner from '../../assets/coreContent/Page 11/new/fullbanner.png'
import centreImage from '../../assets/coreContent/Page 11/new/mapwithtext.png'

function TemplateNineHarvest({voiceOver}) {
    const navigate = useNavigate()
    const appContext = useContext(ExperienceContext)
    const harvestText = appContext.coreContent.data.page_11_text__harvest_ || []
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        const to = setTimeout(() => {
            setLoading(false)
            return clearTimeout(to)
        }, 300)
    }, [])
  return (
    <Layout loading={loading} title="Harvest" button={<GoldButton onClickAction={() => navigate('/method-1')} voiceOver={voiceOver}>Continue</GoldButton>}>
        <TemplateNineGrid>
            <div className="images">
                </div>
            <div className="map">
                
                <img src={centreImage} alt=""/>
            </div>
            <div className="banner">
                <img src={banner} alt=""/>
            </div>
            <div className="text">
                <PrismicRichText field={harvestText} />
            </div>
        </TemplateNineGrid>
        {voiceOver && <audio src={voiceOver} />}
    </Layout>
  )
}

export default TemplateNineHarvest

const TemplateNineGrid = styled.div`
    box-sizing: border-box;
    height: 100%;
    width: 100%;
    display: grid;
    grid-template-columns: 4rem 1.5fr 3fr 1.5fr 4rem;
    grid-template-rows: 1fr 1fr 4rem;;

    .text {
        box-sizing: border-box;
        grid-row: 2 / 3;
        grid-column: 2 / 5;
        padding-top: 2rem;
        display: flex;
        flex-flow: column nowrap;
        justify-content: center;
        
        width: 100%;
        p {
            margin: .5rem 0;
        }
        strong {
            font-weight: normal;
        }
        ul {
            padding: 0;
        }

    }
    .banner {
        box-sizing: border-box;
        grid-row: 1/2;
        grid-column: 1 / -1;
        img {
            height:auto;
            width: 100%;
        }
    }

    .map {
        z-index: 5;
        box-sizing: border-box;
        grid-row: 1 / 2;
        grid-column: 3 / 4;
        height: 100%;
        width: 100%;
        padding-top: 3rem;
        position: relative;
        .text {
            margin-top: .5rem;
            margin-left: 1rem;
            box-sizing: border-box;
            padding-top: 0;
            position: absolute;
            top: 1.5rem;
            background-color: #fff;
            width: 20%;
            height: 40%;
            p {
                margin: .5rem;
                font-family: 'Savoye LET Plain', Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
                color: #C49F5C;

                @media screen and (max-width: 1300px){
                    font-size: 1.2rem;
                }
            }
        }
        img {
            max-height: 100%;
            width: auto;
            max-width: 100%;
        }
    }

`