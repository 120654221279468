import React, { useEffect , useContext} from 'react'
import TemplateSix from '../templates/6-Large-Text/TemplateSix'
import { ExperienceContext } from '..'
import historyAudio from '../assets/audio/Page 7 - History.mp3'


function PageSevenHistory() {
  const appContext = useContext(ExperienceContext)
  const historyImage = appContext.experienceContent.data.history_image.url || ''
  useEffect(() => {
    appContext.updateExperienceProgress(3)
  })
  return (
    <TemplateSix image={historyImage} voiceOver={historyAudio} />
  )
}

export default PageSevenHistory