//onst apiURL = 'https://champagne-ology-backend.herokuapp.com'

// const apiURL = 'http://localhost:8000'
const apiURL = process.env.REACT_APP_API_URL

export async function signInWithAccessCode(accessCode) {
    const headers = new Headers()
    headers.append("Content-Type", "application/json")
    const requestBody = JSON.stringify({accessCode: accessCode})

    const response = await fetch(`${apiURL}/login`, {
        headers: headers,
        method: 'POST',
        body: requestBody
    })
    if(!response.ok) throw new Error(response.status)
    const orderDetails = await response.json()
    return orderDetails
}

export async function activateAccessCode(accessCode) {
    const headers = new Headers()
    headers.append("Content-Type", "application/json")
    const requestBody = JSON.stringify({activationCode: accessCode})
    const response = await fetch(`${apiURL}/activateCode`, {
        headers: headers,
        method: 'PUT',
        body: requestBody
    })
    const jsonRes = await response.json()
    return jsonRes
}