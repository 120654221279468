import React, { useContext } from 'react'
import TemplateThree from '../templates/3-Experience-Intro/TemplateThreeExpIntro'
import { ExperienceContext } from '..';
import TemplateThreeExpIntro from '../templates/3-Experience-Intro/TemplateThreeExpIntro';
import audio from '../assets/audio/Page 20 - Aurevoir.mp3'


function AuRevoir() {
  const appContext = useContext(ExperienceContext)
  const text = appContext.coreContent.data.page_20_text || []
  const image = appContext.experienceContent.data.au_revoir_image.url || ''
  return (
    <TemplateThreeExpIntro title="Au Revoir" text={text} leftImage={image} goldLines={true} centerText={true} finalButton={true} voiceOver={audio}/>
    //<TemplateThree centerText={true} title="Au Revoir" text={text} leftImage={image} buttonLink="/goodbye"/>
  )
}

export default AuRevoir