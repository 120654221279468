import React, { useState, useContext} from 'react'
import { Link } from 'react-router-dom';
import styled from "styled-components";
import { ExperienceContext } from '../..';

function NabBar() {
  const [showWelcome, setShowWelcome] = useState(false)
  const [showTasting, setShowTasting] = useState(false)
  const [showHistory, setShowHistory] = useState(false)
  const [showMethod, setShowMethod] = useState(false)
  const [showAuRev, setShowAuRev] = useState(false)

  const appContext = useContext(ExperienceContext)
  const exProgress = appContext.experienceProgress
  return (
    <NavBarContainer>
      <div 
      onMouseOver={() => {setShowWelcome(true)}}
      onMouseLeave = {() => setShowWelcome(false)}
       className='navItem'>
        <span>Welcome</span>
        {showWelcome && <WelcomeOptions />}
      </div>
      {exProgress >= 2 && 
        <div className='navItem'
        onMouseOver={() => {setShowTasting(true)}}
        onMouseLeave = {() => setShowTasting(false)}>
          <span>Champagnes</span>
          {showTasting && <TastingOptions />}
        </div>
      }
      {exProgress >= 3 &&
        <div className='navItem'
        onMouseOver={() => {setShowHistory(true)}}
        onMouseLeave = {() => setShowHistory(false)}
        >
          {showHistory && <HistoryOptions />}
          <span>History</span>
        </div>
      }
      {exProgress >= 4 &&
        <div className='navItem'
        onMouseOver={() => {setShowMethod(true)}}
        onMouseLeave = {() => setShowMethod(false)}>
          <span>Method Champagnoise</span>
          {showMethod && <MethodOptions />}
        </div>
      }
      {exProgress >= 5 &&
        <div className='navItem'
        onMouseOver={() => {setShowAuRev(true)}}
        onMouseLeave = {() => setShowAuRev(false)}>
          <span>Au Revoir</span>
          {showAuRev && <AuRevOptions className="last" />}
        </div>
      }
    </NavBarContainer>
  )
}

const WelcomeOptions = () => {
  return (
  <NavDropDown>
    <ul>
      <li><Link to="/experience-intro">Your Experience</Link></li>
      <li><Link to="/preparation">Preparation</Link></li>
    </ul>
  </NavDropDown>

  )
}

const TastingOptions = () => {
  return (
    <NavDropDown>
      <ul>
        <li><Link to="/tasting-notes-1">First Tasting</Link></li>
        <li><Link to="/tasting-notes-2">Main Tasting</Link></li>
        <li><Link to="/tasting-notes-3">Third Tasting</Link></li>
      </ul>
    </NavDropDown>
  )
}

const HistoryOptions = () =>(
  <NavDropDown>
    <ul>
      <li><Link to="/history">A Brief History</Link></li>
      <li><Link to="/timeline">Timeline</Link></li>
      <li><Link to="/vintages">Vintages</Link></li>
    </ul>
  </NavDropDown>
)

const MethodOptions = () => (
  <NavDropDown>
    <ul>
      <li><Link to="/harvest">Harvest</Link></li>
      <li><Link to="/method-1">Pressing</Link></li>
      <li><Link to="/method-2">Fermentation</Link></li>
      <li><Link to="/method-3">Blending</Link></li>
      <li><Link to="/method-4">Ageing</Link></li>
      <li><Link to="/method-5">Riddling</Link></li>
      <li><Link to="/method-6">Dosage</Link></li>
    </ul>
  </NavDropDown>
)

const AuRevOptions = _ => (
  <NavDropDown>
    <ul>
      <li><Link to="/trivia">Champagne Trivia</Link></li>
      <li><Link to="/au-revoir">Thank You</Link></li>
      <li><a href="https://champagne-ology.com">Website</a></li>
    </ul>
  </NavDropDown>
)

export default NabBar


const NavBarContainer = styled.nav`
    color: #C49F5C;
    font-family: 'Savoye LET Plain', serif;
    width: max-content;
    padding: .5rem 1rem;
    padding-left: 0;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-around;

    border-radius: 50px;
    .navItem {
        box-sizing: border-box;
        margin-right: .5rem;
  
        display: flex;
        position: relative;
        border-right: 1px solid  #C49F5C;
        padding-right: 1rem;
        height: min-content;
        width: auto;
        
        span {
            margin-top: 2px;
            text-decoration: none;
            cursor: pointer;
            font-size: 1.5rem;
        }
    }
`

const NavDropDown = styled.div`
box-sizing: border-box;
position: absolute;
top: 1.75rem;
left: -1rem;
width: max-content;;
color: #C49F5C;
z-index: 10;


ul {
  padding: 0;
  margin-bottom: 0;
  box-sizing: border-box;
  width: auto;
  list-style: none;
  color: #C49F5C;
  li {
    background: blue;
    transition: background-color ease-in-out .2s;
    font-size: 1.5rem;
    padding: .5rem 1rem;
    margin: 0;
    background-color: #fff;
    border-top: 1px solid #fff;
    border-bottom: 1px solid #fff;
    cursor: pointer;
    &:hover {
      box-sizing: border-box;
      width: 100%;
      background-color: #fff;
      border-top: 1px solid #C49F5C;
      border-bottom: 1px solid #C49F5C;
    }

    a {
      text-decoration: none;
      color: #fff;
      color: #C49F5C;
    }
  }
  
}


`