import React, { useContext } from 'react'
import TemplateFive from '../templates/5-LeftImage-LargeContent/TemplateFive'
import { ExperienceContext } from '..'
import { tastingAudio } from '../utils/tastingAudio'

function TastingNotesThree() {
  const appContext = useContext(ExperienceContext)
  const bottleImage = appContext.experienceContent.data.tasting_notes_3_image.url || ''
  const tastingNotes = appContext.experienceContent.data.tasting_notes_3_text || []
  const experienceData = appContext.experienceContent.data || {}
  const {
    tasting_1_appearance,
    tasting_1_blend,
    tasting_1_bouqet,
    tasting_1_description,
    tasting_1_dosage,
    tasting_1_effervescence,
    tasting_1_fermentation,
    tasting_1_food_pairing,
    tasting_1_palate,
    tasting_1_producer_type,
    tasting_1_style,
    tasting_1_time_on_lees,
    tasting_1_name,
    tasting_2_name,
    tasting_2_appearance,
    tasting_2_blend,
    tasting_2_bouqet,
    tasting_2_description,
    tasting_2_effervescence,
    tasting_2_fermentation,
    tasting_2_food_pairing,
    tasting_2_palate,
    tasting_2_producer,
    tasting_2_style,
    tasting_2_time_on_lees,
    tasting_2_dosage,
    tasting_3_name,
    tasting_3_appearance,
    tasting_3_blend,
    tasting_3_bouqet,
    tasting_3_description,
    tasting_3_dosage,
    tasting_3_effervescence,
    tasting_3_fermentation,
    tasting_3_food_pairing,
    tasting_3_palate,
    tasting_3_producer,
    tasting_3_style,
    tasting_3_time_on_lees
  } = experienceData
  const audio = tastingAudio[appContext.experienceType].t3
  const textFields = {
    name: tasting_3_name,
    description: tasting_3_description,
    style: tasting_3_style,
    blend: tasting_3_blend,
    fermentation: tasting_3_fermentation,
    timeOnLees: tasting_3_time_on_lees,
    dosage: tasting_3_dosage,
    producer: tasting_3_producer,
    effervescence: tasting_3_effervescence,
    appearance: tasting_3_appearance,
    bouqet: tasting_3_bouqet,
    palate: tasting_3_palate,
    foodPairings: tasting_3_food_pairing
  };
  return (
    <TemplateFive title="Tasting" voiceOver={audio} textFields={textFields} content={tastingNotes} image={bottleImage} buttonLink="/trivia" />
  )
}

export default TastingNotesThree