import React, {useState, useEffect} from 'react'
import Layout from '../Layout/Layout'
import styled from 'styled-components'
import {PrismicRichText} from '@prismicio/react'
import GoldButton from '../../components/GoldButton/GoldButton'
import {useNavigate} from 'react-router-dom'


function IntroTwo({image, text, buttonLink, loading, voiceOver, firstPage}) {
    const navigate = useNavigate()
  return (
    <Layout loading={loading} title="Welcome" button={<GoldButton onClickAction={() => navigate(buttonLink)} voiceOver={voiceOver} firstPage={firstPage}>Continue</GoldButton>}>
        <Grid>
        <div className="image">
            <img alt="welcome" src={image} />
        </div>
        <div className='text'>
            <PrismicRichText field={text} />
        </div>
        <div className="spacer"></div>
        {voiceOver && <audio src={voiceOver} />}
        </Grid>
    </Layout>
  )
}

export default IntroTwo

const Grid = styled.div`
box-sizing: border-box;
display: grid;
height: 100%;
width: 100%;
grid-template-rows: 1fr 1.2fr 6rem;
grid-template-columns: 4rem 1fr 4rem;
row-gap: 1.5rem;

@media screen and (max-width: 1300px ){
    grid-template-columns: 2rem 1fr 2rem;
}

.image {
   
    box-sizing: border-box;
    height: 100%;
    width: 100%;
    grid-column: 1/-1;
    grid-row: 1 / 2;
    
    img {
        width: 100%;
        height: auto;
    }
}

.text {
    margin-top: 1rem;
    box-sizing: border-box;
    grid-column: 2 / 3;
    grid-row: 2 / 3;
    padding-right: 130px;
   
    p {
        margin: .5rem 0;
    }
    p:last-of-type {
        margin-bottom: 0;
    }
}
.spacer {
    grid-column: 1 / -1;
    grid-row: 3/4;
    height: 100%;
}

`