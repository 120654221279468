import React, { useState, useContext } from 'react'
import styled from 'styled-components'
import {useNavigate } from 'react-router-dom'
import {signInWithAccessCode} from '../../utils/api'
import { ExperienceContext } from '../..'
// Components
import GoldButton, { OldGoldButton } from '../../components/GoldButton/GoldButton'
import {AiOutlineMinus} from 'react-icons/ai'
// assets
import backgroundImage from '../../assets/coreContent/Login/background.svg'
import logo from '../../assets/signIn/Logo.svg'
import enterCode from '../../assets/coreContent/Login/entercode.svg'


function Login() {
  const navigate = useNavigate()
  const [ accessCode, setAccessCode ] = useState('')
  const [loginError, setLoginError] = useState(false)
  const appContext = useContext(ExperienceContext)

  async function handleLogin() {
    try {
      const userDetails = await signInWithAccessCode(accessCode)
      appContext.setAccessCode(userDetails.accessCode)
      appContext.updateUserDetails(userDetails)
      console.log(userDetails)
      setLoginError(false)
      const {experienceType} = userDetails
      navigate(`/welcome-intro/${experienceType}`)
    } catch (e) {
      if(e.message === '401') setLoginError(true)
      console.warn(e)
      setAccessCode('');
    }
  }
  return (
    <LoginLayout background={backgroundImage}>
      <div className="loginBox">
        <img className='logo' src={logo} alt="Champagnology Logo" />
        <em>Welcome To</em>
        <em>Champagne-ology</em>
        <p className="subtext">The Ultimate Champagne Tasting Experience</p>
        <div className="enter"><AiOutlineMinus/><p className="entercodetext">Enter Your Code Below</p><AiOutlineMinus/></div>
        <form className='loginForm' onSubmit={e => {
          e.preventDefault()
          handleLogin()
        }}>
          <input type="text"
          id="accessCode"
          value={accessCode}
          onChange={e => setAccessCode(e.target.value)}
          placeholder="e.g. 12345"
          />
          <OldGoldButton type="button" onClickAction={handleLogin} disabled={accessCode.length < 1} >Start Your Experience</OldGoldButton>
          {loginError && <p className='error'>Unable to sign in. Please check you have entered the correct access code. <br/>Contact us through champagne-ology.com if you have any issues.</p>}
          <p className='disc'>*Please note, our experience has been designed to be viewed on a TV, laptop or iPad Pro. Any devices which have a small screen will not display the whole experience as intended.</p>
        </form>
      </div>
    </LoginLayout>
  )
}

export default Login

const LoginLayout = styled.main`
  box-sizing: border-box;
  display: grid;
  height: 100vh;
  background-image: url(${backgroundImage});
  background-size: cover;
  grid-template-columns: 3fr 3fr 3fr;
  grid-template-rows: minmax(4rem, auto) 1fr minmax(4rem, 0);

  @media screen and (max-width: 1500px) {
      grid-template-columns:1.5fr 3fr 1.5fr;
      }
    @media screen and (max-width: 780px) {
      grid-template-columns:1fr 400px 1fr;
      }
  
  .loginBox {
    box-sizing: border-box;
    grid-row: 2 / 3;
    grid-column: 2 / 3;
    background-color: #ffffff84;
    backdrop-filter: blur(6px);
    border-radius: 15px;
    border: 1px solid #C49F5C;
    text-align: center;
    height: max-content;
    padding-bottom: 4rem;

    img.logo {
      width: 50%;
      height: auto;
      margin-bottom: -2rem;
    }
  

    em {
      font-size: 3rem;
      display: block;
      &:last-of-type {
        margin-bottom: 1rem;
      }
    }
    .enter {
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
      color: #C49F5C;
    }

    p.entercodetext {
      margin: 1rem .5rem;
      text-align: center;
      color: #C49F5C;
      font-family: 'Source Sans Light' Verdana, Geneva, Tahoma, sans-serif
    }
    }

    p.subtext {
      margin: 0;
      text-align: center;
      font-size: 2rem;
      color: #C49F5C;
      font-family: 'Savoye LET Plain', serif;
    
    }

    @media screen and (max-width: 1300px) {
       em {font-size: 2.5rem; }
       p.subtext {font-size: 2rem;}
      }
    @media screen and (max-width: 1000px) {
       em {font-size: 2rem; }
       p.subtext {font-size: 1.5rem;}
      }
  }
  .loginForm {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;

    button {
      width: max-content;
      flex-grow: 0;
      margin-top: 1rem;
    }
    input {
      box-sizing: border-box;
      width: 200px;
      text-align: center;
      height: 2.2rem;
      border: 1px solid #C49F5C;
      &:focus-visible {
        outline: 1px solid #c8ae7d;
      }

      @media screen and (max-width: 1000px) {
        width: 150px;
        height: 1.8rem;
    }
    }
  }
  .error {
    width: 70%;
    text-align: center;
  }
  .disc {
    width: 70%;
    color: gray;
    font-size: .8rem;
    text-align: center;
  }
`
const EnterCode = styled.div`
      box-sizing: border-box;
      padding: 0;
      background-image: url(${enterCode});
      background-size: contain;
      background-repeat: no-repeat;
    
      width: 250px;
      height: 40px;
      margin: 0 auto;
      @media screen and (max-width: 1000px) {
        width: 150px;
        height: 20px;
  }
`

// Nav links (for development)
/* 
<nav>
  <Link to="/welcome/blanc-de-blanc">Blanc de Blanc</Link>
  <Link to="/welcome/pinot-noir">Pinot Noir</Link>
  <Link to="/welcome/pinot-meunier-and-pinot-noir">Pinot Meunier & Pinot Noir</Link>
  <Link to="/welcome/rose-pinot">Rose Pinot</Link>
</nav> 
*/