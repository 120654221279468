import React, { useContext, useEffect } from "react";
import TemplateTwo from "../templates/2-Welcome-Intro/TemplateTwoIntro";
import { ExperienceContext } from "..";
import TemplateThreeExpIntro from "../templates/3-Experience-Intro/TemplateThreeExpIntro";
import audio from '../assets/audio/Page 12 - Pressing.mp3'

function PageElevenMethod() {
  const appContext = useContext(ExperienceContext);
  const grapesInBarrelPic =
    appContext.coreContent.data.page_12_image__method_1_.url || "";
  const content = appContext.coreContent.data.page_12_text__method_1_ || [];
  useEffect(() => {
    appContext.updateExperienceProgress(4);
  });
  return (
    <TemplateThreeExpIntro
      title="Method Champenoise"
      headingDecor={true}
      goldLines={false}
      leftImage={grapesInBarrelPic}
      text={content}
      buttonLink="/method-2"
      method={true}
    voiceOver={audio}
    />
  );
}

export default PageElevenMethod;
