import React, { useContext} from 'react'
import TemplateEight from '../templates/8-Image-Info-Graphic/TemplateEight'
import { ExperienceContext } from '..'
import MethodTwo from '../templates/12-  MethodTwo/MethodTwo'
import audio from '../assets/audio/Page 15 - Second Fermentation.mp3'

function MethodPageFive() {
  const appContext = useContext(ExperienceContext)
  console.log(appContext)
  const topText = appContext.coreContent.data.page_15_text__method_4_ || []
  const bottomText = appContext.coreContent.data.page15_text_bottom || []
  const cellarImage = appContext.coreContent.data.page_15_image__method_4_.url || ''
  return (
    <MethodTwo
    topRightText={topText}
    bottomLeftText={bottomText}
    shrinkCentreColumn={true}
    image2Url={cellarImage}
    wideTopText={true}
    title="Method Champenoise"
    buttonLink="/method-5"
    voiceOver={audio}
    />
   // <TemplateEight title="Method Champenoise" image={cellarImage} text={text} buttonLink="/method-5" />
  )
}

export default MethodPageFive