import React from 'react'
import styled from 'styled-components/macro'
import siteLogo from '../../assets/svg/siteLogo2.png'

function PageTitle({title, name}) {
  const newTitles = [
    'Happy Birthday',
    'Congratulations',
    'Merry Christmas',
    'Hen Party',
    'Happy Anniversary',
    'Be My Valentines',
    'Thank You'
  ]

  function generateTitle(title) {
    if(title === 'Blank') return <h1>Welcome</h1>
    if(newTitles.includes(title)) return <h1>{title}</h1>
    return <h1>Welcome {title} {name}</h1>
  }


  return (
    <TitleBar>
      <div className="logo-container">
        <img src={siteLogo} alt="Champagnology Logo" />

      </div>
        {generateTitle(title)}
    </TitleBar>
  )
}

export default PageTitle

// Styles
const TitleBar = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 1.5rem;

    .logo-container {
 
      display: flex;
      flex-flow: column nowrap;
      justify-content: center;
      vertical-align: center;
      width: 350px;

      img {
        width: 100%;
        height: auto;
        margin-left: -3px;
      }

    }


    h1 {
        font-family: 'Savoye LET Plain', Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
        color: #c49f5c;
        font-size: 2rem;
        font-weight: normal;
        margin: 0 1rem;
        margin-right: 0;
    }
`