import React, { useContext, useState } from 'react'
import styled from 'styled-components'
import Layout from '../Layout/Layout'
import GoldButton from '../../components/GoldButton/GoldButton'
import { useNavigate } from 'react-router-dom'

import { ExperienceContext } from '../../index'

function TemplateSeven({voiceOver}) {
    const navigate = useNavigate()
    const appContext = useContext(ExperienceContext)
    const timelineImage = appContext.coreContent.data.page_8_image__timeline_.url || ''
    const [loading, setLoading] = useState(true)
  return (
    <Layout loading={loading} title="A Brief History" button={<GoldButton onClickAction={() => navigate('/vintages')} voiceOver={voiceOver}>Continue</GoldButton>}>
        <TemplateSevenGrid>
            <div className="text">
                <strong>Through the first half of the 1800s, the industry saw the opening of more Champagne houses and an evolution in Champagne making techniques.</strong>
            </div>
            <div className="image">
                <img onLoad={() => setLoading(false)} src={timelineImage}  alt=""/>
            </div>
        </TemplateSevenGrid>
        {voiceOver && <audio src={voiceOver} />}
    </Layout>
  )
}

export default TemplateSeven

const TemplateSevenGrid = styled.div`
box-sizing: border-box;
    height: 100%;
    display: grid;
    grid-template-rows: 2rem 1fr 40px;
    grid-template-columns: 2rem 1fr 2rem;
    @media screen and (max-width: 1200px){
        grid-template-rows: 4rem 1fr 20px;
        }

    .text {
        grid-row: 1 / 2;
        grid-column: 2 /3;
        display: flex;
        justify-content: center;
        width: 100%;
        strong {
            font-weight: normal;
        }
        @media screen and (max-width: 1200px){
            strong {
                margin-top: 2rem;
            }
        }
    }

    .image {
        box-sizing: border-box;
        height: 90%;
        grid-row: 2/3;
        grid-column: 2/3;

        img {
            max-width:100%;
            max-height: 100%;
        }
    }
`